import apiHandler from "../../api/api";

//action constants
const SET_BLOGPOSTS = 'SET_BLOGPOSTS';
const SET_BLOGPOST = 'SET_BLOGPOST';
const CLEAR_SINGLE_POST = 'CLEAR_SINGLE_POST';

//initial state
const initState = {
   blog: [],
   singlePostData: {},
}

//reducer
const blogPageReducer = (state = initState, action) => {
   switch (action.type) {
      case SET_BLOGPOSTS:
         return {
            ...state,
            blog: action.blogPosts,
         }
      case SET_BLOGPOST:
         return {
            ...state,
            singlePostData: action.postData,
         }
      case CLEAR_SINGLE_POST:
         return {
            ...state,
            singlePostData: {},
         }
      default:
         return state;
   }
}

export default blogPageReducer;


//action creators
// export const follow = (userId) => ({type: FOLLOW, userId: userId});
export const clearSinglePostData = () => {
   return {
      type: CLEAR_SINGLE_POST,
   }
}

//thunk creators
export const fetchBlogPosts = () => {
   return dispatch => {
      apiHandler('getBlogPosts')
         .then(res => {
            dispatch({type: SET_BLOGPOSTS, blogPosts: res});
         })
   }
}

export const fetchSinglePost = (id) => {
   return dispatch => {
      apiHandler('getBlogPost', {BlogPostid: id})
         .then(res => {
            dispatch({type: SET_BLOGPOST, postData: res});
         })
   }
}

