//action constants
import apiHandler from "../../api/api";

const GET_CART = 'GET_CART';
const ADD_CART_ITEM = 'ADD_CART_ITEM';
const CLEAR_CART = 'CLEAR_CART';

//initial state
const initState = {
   cart: null,
}

//reducer
const cartReducer = (state = initState, action) => {
   switch (action.type) {
      case GET_CART:
         return {
            ...state,
            cart: action.cartData,
         }
      case CLEAR_CART:
         return {
            ...state,
            cart: [],
         }
      default:
         return state;
   }
}

export default cartReducer;


//action creators
const setCart = (cartData, dispatch) => {
   dispatch({type: GET_CART, cartData: cartData})
}

const getCartHelper = (dispatch) => {
   window.testCart = () => {
      apiHandler('getCartItems')
         .then(res => {
            setCart(res, dispatch);
         });
   }

   apiHandler('getCartItems')
      .then(res => {
         setCart(res, dispatch);
      })
}

export const getCart = () => {
   return dispatch => {
      getCartHelper(dispatch);
   }
}

export const clearCart = () => {
   return dispatch => {
      apiHandler('clearCart')
         .then(() => {
            dispatch({type: CLEAR_CART})
         })
   }
}

const getTransformedItemData = (id, amount, customizables) => {
   // transforms customizables
   let customizablesArr = [];
   customizables.forEach((curVal, i) => {
      const itemIndex = customizablesArr.findIndex(el => el.id === curVal.optId);
      if(itemIndex !== -1){
         customizablesArr[itemIndex].packs.push(curVal.packId);
      } else {
         customizablesArr.push({
            id: curVal.optId,
            packs: [curVal.packId]
         })
      }
   });

   // create itemData object
   return {
      Item: {
         id: id,
         amount: amount,
         customizables: customizablesArr,
      }
   }

}

export const addCartItem = (id, amount, customizables) => {
   const itemData = getTransformedItemData(id, amount, customizables);

   return dispatch => {
      apiHandler('addCartItem', itemData)
         .then(res => {
            setCart(res, dispatch);
         })
   }
}

export const removeCartItem = (index) => {
   return dispatch => {
      return apiHandler('removeCartItem', {Itemid: index})
         .then(res => {
            // debugger;
            setCart(res, dispatch);
         })
   }
}

export const sendVerifCode = (phone) => {
   return dispatch => {
      return apiHandler('sendVerificationCode', {
         phone_number: phone,
      })
   }
}

export const checkoutFormSubmit = (dataObj, verifCode, isScheduled = false, marker) => {
   return dispatch => {
      console.log(dataObj);
      const newObj = {
         verification_code: verifCode,
         firstname: dataObj.firstName,
         lastname : dataObj.lastName,
         address: dataObj.street,
         phone_number : dataObj.phone,
         email: dataObj.email,
         comment: dataObj.comment,
         latitude : marker.x,
         longitude: marker.y,
         payment_type : dataObj.paymentMethod,
         delivery_type: parseInt(dataObj.deliveryMethod),
      }

      if(isScheduled){
         newObj.delivery_scheduled = dataObj.deliveryScheduled.toISOString().slice(0, 10) + ` ${dataObj.deliveryTime}:00`;
         debugger;
      }

      return apiHandler('checkout', newObj)
   }
}






