import React from 'react';
import s from './Label.module.css';
import PropTypes from "prop-types";
import TextInput from "../TextInput/TextInput";

const Label = (props) => {
   return (
      <>
         <label htmlFor={props.name} className={s.label} >{props.label}:</label>
         {props.error && props.touched
            ? <div className={s.error}>{props.error}</div>
            : null
         }
      </>
   );
};

export default Label;

Label.propTypes = {
   label: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   error: PropTypes.string.isRequired,
   touched: PropTypes.bool.isRequired,
}