import React, {Component, createRef} from 'react';
import s from './SingleJob.module.css';
import bannerImg from "../../assets/images/banner2.jpg";
import TitleBanner from "../common/TitleBanner/TitleBanner";
import {Col, Container, Row} from "react-bootstrap";
import Jobs from "../Career/Jobs/Jobs";
import {connect} from "react-redux";
import {getSingleJob} from "../../store/reducers/careerReducer";
import CheckoutFormInputs from "../Checkout/CheckoutFormInputs";
import {ErrorMessage, Field, Form, Formik} from "formik";
import BaseBtn from "../common/NormalBtns/BaseBtn";
import * as Yup from "yup";
import ScrollToTop from "../common/ScrollToTop/ScrollToTop";
import withStr from "../../hoc/withStr";
import apiHandler from "../../api/api";
import gsap from "gsap/gsap-core";
import Loader from "../common/Loader/Loader";

class SingleJob extends Component {

   state = {
      showSuccess: false,
      pageLoaded: false,
   }

   postSuccessRef = createRef();

   componentDidMount() {
      this.props.getSingleJob(this.props.match.params.id);
      gsap.set(this.postSuccessRef.current, {display: 'none', opacity: 0});
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.singleJobData !== this.props.singleJobData){
         this.setState({
            pageLoaded: true,
         })
      }

      if(this.state.showSuccess){
         this.setState({
            showSuccess: false,
         })

         gsap.to(this.postSuccessRef.current, {display: 'flex', opacity: 1, duration: 0.3});

         setTimeout(() => {
            gsap.to(this.postSuccessRef.current, {
               display: 'none',
               opacity: 0,
               duration: 0.3
            });
         }, 3000);

      }
   }

   validationSchema = () => {
      return Yup.object().shape({
         firstName: Yup.string()
            .required('Required'),
         lastName: Yup.string()
            .required('Required'),
         phone: Yup.string()
            .required('Required'),
         email: Yup.string()
            .email('Invalid email')
            .required('Required'),
         cvText: Yup.string()
            .max(300, 'Maximum character count reached')
            .required('Required'),

      });
   }

   render() {

      const renderNormalFormikInput = (data, type, errors, touched, label) => {
         const {field, form, meta} = data;
         return (
            <>
               <label htmlFor={field.name}>{label}</label>
               {errors[field.name] && touched[field.name]
                  ? <div className={s.error}>{errors[field.name] && touched[field.name] && errors[field.name]}</div>
                  : null
               }
               {type !== 'textarea'
                  ? <input
                     id={field.name}
                     className={`form-control ${s.input}`}
                     type={type}
                     name={field.name}
                     value={field.value}
                     onChange={field.onChange}
                     onBlur={field.onBlur}
                  />
                  : <textarea
                     id={field.name}
                     className={`form-control ${s.input}`}
                     name={field.name}
                     value={field.value}
                     onChange={field.onChange}
                     onBlur={field.onBlur}
                     cols="30"
                     rows="10" />
               }
            </>
         )
      }

      const getBlogContent = () => {
         return {__html: this.props.singleJobData.content};
      }

      return (
         <div>
            {!this.state.pageLoaded
                ? <Loader/>
                : null
            }
            <ScrollToTop />
            {/* modal */}
            <div ref={this.postSuccessRef} className={s.checkoutSuccessWrp}>
               <div className={s.bgDarkTransparent}></div>
               <div className={s.checkoutSuccessText}>{this.props.getStr('job_submit_success') || 'Your data has been sent'}
                  <i className="fas fa-check-circle ml-2"></i>
               </div>
            </div>
            {/* /. */}
            <TitleBanner imageURL={bannerImg} text={this.props.getStr('single_job')} />
            <div className={`mt-3 ${s.wrp}`}>
               <Container>
                  <Row className={'justify-content-between'}>
                     <Col md={6} className={'mb-5'}>
                        <h2 className={'mb-4'}>{this.props.singleJobData.title}</h2>
                        <div dangerouslySetInnerHTML={getBlogContent()}></div>
                     </Col>

                     <Col md={5}>
                        <h5 className={'mb-4'}>{this.props.getStr('submit_application')}</h5>
                        <Formik
                           initialValues={{
                              email: '',
                              firstName: '',
                              lastName: '',
                              phone: '',
                              cvText: '',
                           }}
                           validationSchema={() => this.validationSchema()}
                           onSubmit={(values, { setSubmitting }) => {

                              const formData = {
                                 CareerPostid: this.props.match.params.id,
                                 firstname: values.firstName,
                                 lastname: values.lastName,
                                 phonenumber: values.phone,
                                 email: values.email,
                                 text: values.cvText
                              }

                              apiHandler('submitCareerEntry', formData)
                                  .then(res => {
                                     if(res.status === 0){
                                        // success
                                        this.setState({
                                           showSuccess: true,
                                        })
                                     }
                                  });

                              setSubmitting(false);
                           }}
                        >
                           {(fmk) => (
                              <Form>
                                 <Field name="firstName" >
                                    {(data) => renderNormalFormikInput(data, 'text', fmk.errors, fmk.touched, this.props.getStr('first_name'))}
                                 </Field>

                                 <Field name="lastName" >
                                    {(data) => renderNormalFormikInput(data, 'text', fmk.errors, fmk.touched, this.props.getStr('last_name'))}
                                 </Field>

                                 <Field name="phone" >
                                    {(data) => renderNormalFormikInput(data, 'text', fmk.errors, fmk.touched, this.props.getStr('phone_field'))}
                                 </Field>

                                 <Field name="email" >
                                    {(data) => renderNormalFormikInput(data, 'email', fmk.errors, fmk.touched,  this.props.getStr('email_field'))}
                                 </Field>

                                 <Field name="cvText">
                                    {(data) => renderNormalFormikInput(data, 'textarea', fmk.errors, fmk.touched,  this.props.getStr('cv_field'))}
                                 </Field>

                                 <BaseBtn
                                    btnType={'Yellow'}
                                    size={'md'}
                                    type="submit"
                                    text={ this.props.getStr('submit')}
                                 />

                              </Form>
                           )}
                        </Formik>
                     </Col>

                  </Row>
               </Container>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => ({
   singleJobData: state.career.singleJobData,
});

const mapDispatchToProps = dispatch => ({
   getSingleJob: (id) => dispatch(getSingleJob(id)),

});

export default connect(mapStateToProps, mapDispatchToProps)(withStr(SingleJob));