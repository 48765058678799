import s from "./Cart.module.css";
import React from "react";
import {Col, Container, Row} from "react-bootstrap";

const CartSummary = props => {
    if(!props.cartData){
        return null;
    }

    if(props.cartData.length === 0){
        return (
           <Container fluid className={s.summary}>
               <div className={'text-center'}>{props.getStr('cart_empty')}</div>
           </Container>
        )
    }

    let orderTotalPrice = 0;
    let finalPrice = 0;


    orderTotalPrice = props.cartData.reduce((accum, cur) => {
        return accum + cur.real_price;
    }, 0);
    orderTotalPrice = Math.round(orderTotalPrice*100)/100;

    let orderFinalJSX = null;
    if(props.deliveryPrice) {
        finalPrice = Math.round(orderTotalPrice*100 + props.deliveryPrice*100)/100;
        orderFinalJSX = (
            <>
                <Row className={'mb-1'}>
                    <Col xs={7} className={s.summaryLeft}>{props.getStr('delivery_price')}:</Col>
                    <Col xs={5} className={s.summaryRight}>{props.deliveryPrice} GEL</Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={7} className={`${s.summaryLeft} ${s.summaryLeftLg}`}>{props.getStr('order_total')}:</Col>
                    <Col xs={5} className={`${s.summaryRight} ${s.summaryLeftLg}`}>{finalPrice} GEL</Col>
                </Row>
            </>
        );
    }

    return (<Container fluid className={s.summary}>
        <Row className={'mb-1'}>
            <Col xs={7} className={s.summaryLeft}>{props.getStr('order_price')}:</Col>
            <Col xs={5} className={s.summaryRight}>{orderTotalPrice} GEL</Col>
        </Row>
        {orderFinalJSX}

    </Container>)
}

export default CartSummary;