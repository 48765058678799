import React, {Component} from 'react';
import {Formik} from "formik";
import * as PropTypes from "prop-types";
import {Col} from "react-bootstrap";
import CheckoutFormInputs from "./CheckoutFormInputs";
import * as Yup from 'yup';
import s from './CheckoutForm.module.css'



class CheckoutForm extends Component {
    // required
    // invalid_email
    // maximum_char
   validationSchema = () => {
      const configObj = {
         firstName: Yup.string()
            .required(this.props.getStr('required')),
         lastName: Yup.string()
            .required(this.props.getStr('required')),
         // street: Yup.string()
         //    .required('Required'),
         phone: Yup.string()
            .required(this.props.getStr('required')),
         email: Yup.string()
            .email(this.props.getStr('invalid_email')),
            //.required('Required'),
         comment: Yup.string()
            .max(150, this.props.getStr('maximum_char')),
      }

      const yupObj = Yup.object().shape(configObj);

      return yupObj;
   }

   render() {


      return (
         <div className={s.formWrp}>
            <Formik
               initialValues={{
                  firstName: '',
                  lastName: '',
                  street: '',
                  phone: '',
                  email: '',
                  comment: '',
                  deliveryMethod: 1,
                  paymentMethod: 1,
               }}
               validationSchema={() => this.validationSchema()}
               onSubmit={(values, { setSubmitting }) => {
                  if(!this.props.cartData || this.props.cartData.length === 0){
                     return;
                  }

                  const generatedValues = {
                      ...values,
                      deliveryTime: this.props.deliveryTime,
                  }

                  this.props.onShowPopup(generatedValues);
                  setSubmitting(false);
               }}
            >
               {(fmk) => {
                     return (
                        <CheckoutFormInputs
                           {...fmk}
                           deliveryTime={this.props.deliveryTime}
                           showTimePicker={this.props.showTimePicker}
                           showTimePickerHandler={this.props.showTimePickerHandler}
                           setTime={this.props.setTime}

                           mapMarker={this.props.mapMarker}
                           setMapMarker={this.props.setMapMarker}
                           addressChangeHandler={this.props.addressChangeHandler}
                           addressBlurHandler={this.props.addressBlurHandler}
                           addressInput={this.props.addressInput}

                           showCalendar={this.props.showCalendar}
                           serverError={this.props.serverError}
                           inputDate={this.props.inputDate}
                           onInputDateChanged={this.props.onInputDateChanged}
                           onDeliveryChangedHandler={this.props.onDeliveryChangedHandler}
                           deliveryOptions={this.props.deliveryOptions}
                           paymentOptions={this.props.paymentOptions}

                           getStr={this.props.getStr}
                        />
                     )
                  }
               }
            </Formik>
         </div>
      );
   }
}

export default CheckoutForm;