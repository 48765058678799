import React, {Component} from "react";
import {connect} from "react-redux";
import {chooseLang, getStrings, initLanguages} from "../../store/reducers/appReducer";
import Loader from "../../components/common/Loader/Loader";
import {Style} from "react-style-tag";


const withLang = (Comp) => {
    class withLangComp extends Component {
        state = {
            isLoaded: false,
        }

        componentDidMount() {
            const queryParams = new URLSearchParams(this.props.location.search);
            let newLangId = 1;

            if(queryParams.get('lang')){
                newLangId = parseInt(queryParams.get('lang'));
            }

            this.props.initLanguages(newLangId)
                .then(() => {
                    this.setState({
                        isLoaded: true,
                    })
                })
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if(this.props.langId !== prevProps.langId){
                this.setState({
                    isLoaded: false,
                });
                // debugger;
                this.props.getStrings(this.props.langId)
                    .then(() => {
                        this.setState({
                            isLoaded: true,
                        });
                    })
            }
        }

        chooseLangHandler = (langId) => {
            const queryParams = new URLSearchParams(this.props.location.search);
            queryParams.set('lang', langId);
            this.props.history.push(this.props.location.pathname + '?' + queryParams.toString())

            this.props.chooseLang(langId);
        }

        renderLanguage = () => {

            const renderStyleTag = (boldFont, regFont) => {
                return <Style>
                {`
                      body{
                        font-family: '${regFont}'
                      }
                      h1,h2,h3,h4,h5,h6{
                        font-family: '${boldFont}'
                      }
                  `}
                </Style>
            }

            switch(this.props.langId){
                case 1:
                    //geo
                    return renderStyleTag('geoBold', 'geoReg');
                case 2:
                    // eng
                    return renderStyleTag('engBold', 'engReg');
                case 3:
                    //rus
                    return renderStyleTag('rusBold', 'rusReg');
                default:
                    return renderStyleTag('geoBold', 'geoReg');
                    // geo
            }
        }

        render(){
            if(!this.props.languages || this.props.languages.length === 0 || !this.state.isLoaded){
                return <Loader />;
            }

            return (
                <>
                    {this.renderLanguage()}
                    <Comp
                        chooseLangHandler={this.chooseLangHandler}
                        {...this.props}
                    />
                </>
            )
        }
    }

    const mapStateToProps = state => ({
        languages: state.app.languages,
        langId: state.app.langId
    });

    const mapDispatchToProps = dispatch => ({
        initLanguages: (langId) => dispatch(initLanguages(langId)),
        getStrings: (langId) => dispatch(getStrings(langId)),
        chooseLang: (lang) => dispatch(chooseLang(lang)),
    })

    return connect(mapStateToProps, mapDispatchToProps)(withLangComp);
}

export default withLang;