import React from "react";
import Ingredient from "./Ingredient";

const Ingredients = props => {
   if(!props.selectedOptions) return null;

   const isCheckedFunc = (packId) => {
      const arr = props.selectedOptions.filter(el => {
         return (el.optId === props.optId && el.packId === packId)
      });
      return arr.length > 0
   }

   return (
      <>
         {props.packs.map((pack, i) => {

               const isChecked = isCheckedFunc(pack.id);

               return <Ingredient
                        key={pack.id}
                        type={props.type}
                        name={pack.name}
                        priceModif={pack.price_modifier}
                        onIngredientClicked={() => props.onIngredientClicked(props.optId, pack.id, props.type)}
                        isChecked={isChecked}
                     />
            }
         )}
      </>
   )
}

export default Ingredients;