import React from "react";
import Product from "./Product";
import {Row} from "react-bootstrap";
import {imagePrepath} from "../../helpers";
import withStr from "../../hoc/withStr";

const Products = props => {
    const products = () => {
        return props.productsArr.map(prod => {
            const addToCartClickedHandler = () => {
                props.onRenderPopup(prod.id, prod.name, prod.description, prod.price, prod.image_name);
            }

            return <Product key={prod.id}
                id={prod.id}
                name={prod.name}
                desc={prod.description}
                price={prod.price}
                imageLink={imagePrepath(prod.image_name)}
                sizeMixins={props.sizeMixins}
                callback={addToCartClickedHandler}
                getStr={props.getStr}
                className={props.className}
            />
        })
    }

    return (
        <Row>{products()}</Row>
    )
}

export default withStr(Products);