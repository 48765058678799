import React, {Component, createRef} from "react";
import {Col, Row} from "react-bootstrap";
import s from './CheckoutFormInputs.module.css';
import * as PropTypes from "prop-types";
import BaseBtn from "../common/NormalBtns/BaseBtn";
import Checkbox from "../common/Checkbox/Checkbox";
import Radio from "../common/Radio/Radio";
import DatePicker from "react-datepicker";
import TimeKeeper from 'react-timekeeper';
import CheckoutMap from "./CheckoutMap";
import TextInput from "../common/FormInputs/TextInput/TextInput";


class CheckoutFormInputs extends Component {

   timePickerRef = createRef();



   render(){

      let {
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         setFieldValue
      } = this.props;

      const inputTopJSX = (label, name) => {
         return (
            <>
               <label htmlFor={name} className={s.label} >{label}:</label>
               {errors[name] && touched[name]
                  ? <div className={s.error}>{errors[name] && touched[name] && errors[name]}</div>
                  : null
               }
            </>
         )
      }



      const renderTextInput = (label, name, value, isTextarea, bootstrapSizeConfig = {xl: 6}, ) => {
         const InputComp = isTextarea ? 'textarea' : 'input';

         return <Col {...bootstrapSizeConfig} >
            {inputTopJSX(label, name)}
            <InputComp
               id={name}
               className={`form-control ${s.input}`}
               type="text"
               name={name}
               onChange={handleChange}
               onBlur={handleBlur}
               value={value}
            />
         </Col>
      }

      const renderSelect = (label, name, value, options, callbackFunc=undefined, bootstrapSizeConfig = {xl: 6}) => {
         return <Col {...bootstrapSizeConfig}>
            {inputTopJSX(label, name)}
            <select
               id={name}
               className={`form-control ${s.input}`}
               name={name}
               onChange={callbackFunc ?? handleChange}
               onBlur={handleBlur}
               value={value}
            >
               {options.map((opt, i) => <option key={opt.id} value={opt.id}>{opt.name} - {opt.price} GEL</option>)}
            </select>
         </Col>
      }

      const renderRadio = (label, name, value, options, bootstrapSizeConfig = {xl: 6}) => {
         return <Col xs={12}>
            <Row className={'d-flex justify-content-between'}>
               {options.map( (opt, i) => {
                     return (
                        <Col key={opt.id} {...bootstrapSizeConfig} className={'mb-2'}>
                           <Radio
                              name={opt.name}
                              isChecked={values[name] === (i+1)}
                              value={opt.type}
                              onChangeHandler={() => {
                                 setFieldValue(name, (i+1))
                              }}
                           />
                        </Col>
                     )
                  }
               )}
            </Row>
         </Col>
      }


      return (
         <form className='row align-items-end' onSubmit={handleSubmit}>

            <Col xs={12}>
               <div className={`d-flex align-items-center ${s.headlineWrp}`}>
                  <i className={`fas fa-user h4 mb-0 ${s.icon}`}></i>
                  <h3 className={s.headline}>{this.props.getStr('checkout_general_info')}</h3>
               </div>
            </Col>

            {this.props.serverError
               ? <Col xs={12}>
                  <div className={s.serverError}>{this.props.serverError}</div>
               </Col>
               : null
            }


            {renderTextInput(this.props.getStr('first_name'), 'firstName', values.firstName)}

            {renderTextInput(this.props.getStr('last_name'), 'lastName', values.lastName)}

            {/*{renderTextInput('Address', 'street', values.street, false )}*/}
            <TextInput
               label={this.props.getStr('address_field')}
               name={'street'}
               value={this.props.addressInput.value}
               error={this.props.addressInput.error}
               touched={this.props.addressInput.touched}
               changeHandler={this.props.addressChangeHandler}
               blurHandler={this.props.addressBlurHandler}
            />

            {/* google map for address selection*/}
            <Col xs={12} className={'mt-2 mb-5'}>
               <CheckoutMap
                  mapMarker={this.props.mapMarker}
                  setMapMarker={this.props.setMapMarker}
                  setAddressValue={this.props.addressChangeHandler}
               />
            </Col>
            {/**/}

            {renderTextInput(this.props.getStr('phone_field'), 'phone', values.phone)}

            {renderTextInput(this.props.getStr('email_field'), 'email', values.email)}

            <Col xs={12}>
               <hr className={'mb-0 mt-4'}/>
               <Row>
                  {renderTextInput(this.props.getStr('comment_field'), 'comment', values.comment, true)}
               </Row>
            </Col>



            <Col xs={12} className={'mt-5'}>
               <div className={`d-flex align-items-center ${s.headlineWrp}`}>
                  <i className={`fas fa-truck-loading h4 mb-0 ${s.icon}`}></i>
                  <h3 className={s.headline}>{this.props.getStr('delivery_input_title')}</h3>
               </div>
            </Col>

            {
               renderSelect(this.props.getStr('delivery_method_field'), 'deliveryMethod', values.deliveryMethod, this.props.deliveryOptions, (e) => {
                  this.props.onDeliveryChangedHandler(e.target.value, setFieldValue);
               })
            }


            {this.props.showCalendar()
               ? (
               <>
                  <Col sm={6} >
                     <label className={s.label} >{this.props.getStr('delivery_date_field')}:</label>
                     <DatePicker
                        className={`form-control w-100 ${s.datePicker} ${s.input}`}
                        selected={this.props.inputDate}
                        onChange={this.props.onInputDateChanged}
                        minDate={new Date()}
                     />
                  </Col>
                  <Col sm={6}>
                     <label htmlFor="deliveryTime" className={s.label}>{this.props.getStr('delivery_time_field')}</label>
                     <input
                        ref={this.timePickerRef}
                        id={'deliveryTime'}
                        className={`form-control ${s.input}`}
                        type="text"
                        name={'deliveryTime'}
                        onFocus={() => {
                           this.props.showTimePickerHandler(true);
                        }}
                        onChange={() => {}}
                        value={this.props.deliveryTime}
                     />
                     {this.props.showTimePicker &&
                        <div className={'position-relative'}>
                           <div className={s.timePickerWrp}>
                              <TimeKeeper
                                 time={this.props.deliveryTime}
                                 onChange={(newTime) => this.props.setTime(newTime.formatted24)}
                                 switchToMinuteOnHourSelect
                                 coarseMinutes={5}
                                 forceCoarseMinutes
                                 onDoneClick={() => {
                                    this.props.showTimePickerHandler(false);
                                 }}
                                 hour24Mode
                              />
                           </div>
                        </div>
                     }
                  </Col>
               </>
               )
               : null
            }

            <Col xs={12} className={'mt-5 mb-3'}>
               <div className={`d-flex align-items-center ${s.headlineWrp}`}>
                  <i className={`fas fa-money-check-alt h4 mb-0 ${s.icon}`}></i>
                  <h3 className={s.headline}>{this.props.getStr('payment_method_input_title')}</h3>
               </div>
            </Col>

            {renderRadio(this.props.getStr('payment_method_field'), 'paymentMethod', values.paymentMethod, this.props.paymentOptions, {xs:12})}




            <Col xs={12} className={s.btnWrp}>
               <BaseBtn
                  className={'px-4'}
                  btnType={'Yellow'}
                  text={this.props.getStr('submit_order')}
                  size={'md'}
                  disabled={isSubmitting}
                  type="submit"
               />

            </Col>
         </form>
      )
   }
}

CheckoutFormInputs.propTypes = {
   values: PropTypes.any,
   errors: PropTypes.any,
   touched: PropTypes.any,
   handleChange: PropTypes.any,
   handleBlur: PropTypes.any,
   handleSubmit: PropTypes.any,
   isSubmitting: PropTypes.any
}

export default CheckoutFormInputs;