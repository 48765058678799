import React from 'react';
import s from './Job.module.css';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import BaseBtn from "../../common/NormalBtns/BaseBtn";

const Job = (props) => {
   return (
      <Col xs={12} lg={10} className={'mb-3'}>
         <Row className={`${s.wrp} align-items-center mx-0`}>
            <Col xs={12} lg={6}>
               <h5 className={s.title}>{props.title}</h5>
            </Col>

            {/*<Col xs={12} lg={3} className={s.dateWrp}>*/}
            {/*   <p className={s.date}>ბოლო რიცხვი: 30 სექტემბერი 2020</p>*/}
            {/*</Col>*/}

            <Col xs={12} lg={6} className={`d-flex ${s.btnWrp}`}>
               <BaseBtn
                  btnType={'Yellow'}
                  text={'ვრცლად'}
                  linkPath={'/career/'+props.id}
               />
            </Col>
         </Row>
      </Col>
   );
};

export default Job;