import React, {Component} from 'react';
import s from './OrderDetails.module.css';
import CartItems from "../Navbar/Cart/CartItems";
import CartSummary from "../Navbar/Cart/CartSummary";

class OrderDetails extends Component {
   render() {
      return (
         <div className={s.wrp}>
            <div className={s.titleWrp}>
               <h5>{this.props.getStr('order_details')}</h5>
            </div>
            <CartItems
               getStr={this.props.getStr}
               onCartItemRemoved={this.props.onCartItemRemoved}
               onEditCartItem={this.props.onEditCartItem}
               cartData={this.props.cartData} />
            <CartSummary
                deliveryPrice={this.props.deliveryPrice}
                cartData={this.props.cartData}
                getStr={this.props.getStr}
            />
         </div>
      );
   }
}

export default OrderDetails;