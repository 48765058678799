import s from "./ContentBoxSlider.module.css";
import React, {Component, createRef} from "react";

import Swiper from 'swiper';
import YellowWhiteBtn from "../../common/NormalBtns/YellowWhiteBtn/YellowWhiteBtn";
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import BtnPriceSide from "../../common/BtnPriceSide/BtnPriceSide";
import {getProduct} from "../../../api/api";

class ContentBoxSlider extends Component {

    swiperRef = createRef();
    prevSlideBtnRef = createRef();
    nextSlideBtnRef = createRef();

    componentDidMount() {


    }

    componentDidUpdate(prevProps) {
        if (this.props.currentSlideIndex !== prevProps.currentSlideIndex) {
            const swiperInstance = this.swiperInstance;
            swiperInstance.slideTo(this.props.currentSlideIndex);
        }

        if (prevProps.slides !== this.props.slides && this.props.slides.length > 0) {
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                spaceBetween: 20
            });
            const swiperInstance = this.swiperInstance;
            const setCurrentSlide = this.props.setCurrentSlide;

            //init slideTo using slideIndex in state
            swiperInstance.slideTo(this.props.currentSlideIndex);
            // set event handler for slide change
            swiperInstance.on('slideChange', () => {
                setCurrentSlide(swiperInstance.activeIndex);
            });

            // set buttons for slide changes
            const slideBtnClickHandler = (compVal, trueVal, altVal) => {
                const currentSlideIndex = this.props.currentSlideIndex;
                if (currentSlideIndex !== compVal) {
                    setCurrentSlide(trueVal);
                } else {
                    setCurrentSlide(altVal);
                }
            }

            this.prevSlideBtnRef.current.addEventListener('click', () => {
                slideBtnClickHandler(0, this.props.currentSlideIndex - 1, this.props.slides.length - 1);
            });

            this.nextSlideBtnRef.current.addEventListener('click', () => {
                slideBtnClickHandler(this.props.slides.length - 1, this.props.currentSlideIndex + 1, 0);
            })
        }

    }

    render() {

        const addToCartBtn = (prodId, price) => {
            const callback = () => {
                getProduct(prodId)
                    .then(res => {
                        console.log(res);
                        this.props.onRenderPopupHanlder(
                            res.id,
                            res.name,
                            res.description,
                            res.price,
                            res.image_name,
                        );
                    });

            }

            return (
                <div className={'mt-4'}>
                    <BtnPriceSide
                        text={this.props.getStr('add_to_cart')}
                        price={price}
                        callback={callback}
                    />
                </div>
            )
        }


        const renderBtn = (slide) => {
            if (slide.productid) {
                return addToCartBtn(slide.productid, slide.price)
            } else if (slide.url) {
                return (
                    <a href={slide.url} target={'_blank'}>
                        <BaseBtn btnType={'Yellow'} text={this.props.getStr('see_more')} size={'md'} className={'mt-4'}/>
                    </a>
                )
            }
            return null;
        }


        const slidesJSX = () => {
            return this.props.slides.map(slide => {
                return (
                    <div className={`swiper-slide ${s.swiperSlide}`} key={slide.id}>
                        <h1 className={s.title}>{slide.title}</h1>
                        <p className={s.text}>{slide.text}</p>
                        {renderBtn(slide)}
                    </div>
                )
            })
        }

        return (
            <div className={`${s.contentBoxWrp} d-none`}>

                <div className={s.contentBox}>
                    <div ref={this.swiperRef} className={`swiper-container ${s.swiperContainer}`}>
                        <div className="swiper-wrapper">
                            {slidesJSX()}
                        </div>
                    </div>
                </div>

                <div className={`${s.contentBoxBtnWrp}`}>
                    <div ref={this.prevSlideBtnRef} className={s.contentBoxBtn}>
                        <i className="fas fa-arrow-left"></i>
                    </div>
                    <div ref={this.nextSlideBtnRef} className={s.contentBoxBtn}>
                        <i className="fas fa-arrow-right"></i>
                    </div>
                </div>

            </div>
        )
    }
}

export default ContentBoxSlider;