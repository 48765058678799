import React from "react";
import s from "./IngredientsTitle.module.css";

const IngredientsTitle = props => {
   return (
      <div onClick={props.onDropdownTitleClicked} className={s.dropdownTitleWrp}>
         <i className={`fas fa-chevron-down ${s.arrow} ${props.drpodownIsVisible ? s.arrowUp : s.arrowDown}`}></i>
         <p className={s.dropdownTitle}>{props.text}</p>
      </div>
   )
}

export default IngredientsTitle;