import React, {Component} from 'react';
import s from "./SearchInput.module.css";
import {Col, Container, Row} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import withStr from "../../../hoc/withStr";

class SearchInput extends Component {
    state = {
        searchField: '',
    }

    onSearchSubmitHandler = (e) => {
        e.preventDefault();
        this.props.history.push(`/shop?search=${this.state.searchField}`);
    }

    render() {


        return (
            <Container fluid>
                <Row className={s.wrp}>
                    <Col xs={10} lg={7} xl={5} >
                        <h1 className={s.title}>{this.props.getStr('search')}</h1>
                        <form onSubmit={this.onSearchSubmitHandler}>
                            <div className={'d-flex'}>
                                <input
                                    type="text"
                                    placeholder={this.props.getStr('search_placeholder')}
                                    value={this.state.searchField}
                                    onChange={(e) => {
                                        const newObj = {
                                            searchField: e.target.value
                                        }

                                        this.setState(newObj);

                                        console.log(this.state);

                                    }}
                                    className={`${s.searchForm} form-control py-4`}
                                />
                                <button className={s.searchBtn}>{this.props.getStr('search')}</button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(withStr(SearchInput));