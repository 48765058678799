import React from "react";
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import s from './HomeBlog.module.css';
import {Col, Container, Row} from "react-bootstrap";
import BlogItems from "../../BlogItems/BlogItems";
import {linkWithLang} from "../../../helpers/helpers";

const HomeBlog = props => {
    return (
        <div className={s.wrp}>
            <Container>
                <Row className={'justify-content-center'}>
                    <Col xs={12}>

                        <h1 className={s.title}>{props.getStr('blog')}</h1>
                        <BlogItems itemsArr={props.blogPosts} />
                        <div className={'w-100 text-center mt-3'}>
                            <BaseBtn
                                btnType={'Gray'}
                                size={'md'}
                                text={props.getStr('all_articles')}
                                linkPath={linkWithLang('/blog')}
                            />
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HomeBlog;