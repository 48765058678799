import React from 'react';
import  s from "../../../hoc/withPopup/Ingredients/Ingredient.module.css";

const Checkbox = (props) => {

   const fakeCheckboxClass = props.isChecked ? s.checked : '';

   return (
      <div>
         <div onClick={props.onChangeHandler} className={'d-flex align-items-center'}>
            <input type="checkbox" name={props.name} checked={props.isChecked} onChange={() => {}} className={'d-none'}/>
            <div className={`${s.fakeCheckbox} ${fakeCheckboxClass}`}>
               <i className={`fas fa-check ${s.icon}`}></i>
            </div>
            <label>{props.name}</label>
         </div>
      </div>
   );
};

export default Checkbox;