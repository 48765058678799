import React, {Component, createRef} from "react";
import s from './SpecialOffers.module.css';
import Swiper from 'swiper';
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import {Container} from "react-bootstrap";
import OfferTextBox from "./OfferTextBox";
import SliderPagination from "../../common/SliderPagination/SliderPagination";
import {imagePrepath} from "../../../helpers";

class SpecialOffers extends Component {
    swiperRef = createRef();
    state={
        activeIndex: 0,
    }

    componentWillUnmount() {
        this.setState({
            activeIndex: 0,
        });
        if(this.props.specialOffers.length > 0){
            this.swiperInstance.destroy();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.activeIndex !== this.state.activeIndex){
            this.swiperInstance.slideTo(this.state.activeIndex);
        }

        if(prevProps.specialOffers !== this.props.specialOffers && this.props.specialOffers.length > 0){
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                spaceBetween: 5,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            });
            const swiperInstance = this.swiperInstance;

            swiperInstance.on('slideChange', () => {
                this.setState({
                    activeIndex: swiperInstance.activeIndex
                });
            });
        }
    }


    onPaginationClicked = (i) => {
        this.setState({
            activeIndex: i
        });
    }

    specialOffersToSlidesData = () => {
        return this.props.specialOffers.map(el => ({
            id: el.id,
            imgLink: el.image_name,
            name_en: el.text_en,
            name_ge: el.text_ge,
            title_en: el.title_en,
            title_ge: el.title_ge,
            productid: el.productid,
            price: el.price,
        }));
    }

    render(){
        const slidesArr = this.specialOffersToSlidesData();
        const slidesJSX = () => {
            return slidesArr.map(slide => {
                return (
                    <div key={slide.id} className={`swiper-slide`} >
                        <div className={'d-flex flex-wrap align-items-end'}>
                            <div className={s.slideImgWrp}>
                                <div className={s.slideImgInnerWrp}>
                                    <img src={imagePrepath(slide.imgLink)} className={s.slideImg}  alt="special offer image"/>
                                </div>
                            </div>

                            <OfferTextBox
                               onRenderPopupHanlder={this.props.onRenderPopupHanlder}
                               prodId={slide.productid}
                               price={slide.price}
                               slideTitle={slide.title_ge}
                               slideText={slide.name_ge}
                               getStr={this.props.getStr}
                            />

                        </div>
                    </div>
                )
            });
        }

        return (
            <Container>
                <h1 className={'my-5'}>{this.props.getStr('special_offers')}</h1>
                <div ref={this.swiperRef} className={`swiper-container ${s.swiperContainer}`}>
                    <div className="swiper-wrapper">
                        {slidesJSX()}
                    </div>

                    <SliderPagination
                        onPaginationClicked={this.onPaginationClicked}
                        slides={slidesArr}
                        activeIndex={this.state.activeIndex}/>

                </div>
            </Container>
        )
    }
}

export default SpecialOffers;