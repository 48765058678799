import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";

import homePageReducer from './reducers/homePageReducer';
import popupReducer from "./reducers/popupReducer";
import blogPageReducer from "./reducers/blogPageReducer";
import shopPageReducer from "./reducers/shopPageReducer";
import cartReducer from "./reducers/cartReducer";
import careerReducer from "./reducers/careerReducer";
import dynamicPageReducer from "./reducers/dynamicPageReducer";
import appReducer from "./reducers/appReducer";

let reducers = combineReducers({
    homePage: homePageReducer,
    blogPage: blogPageReducer,
    popup: popupReducer,
    shop: shopPageReducer,
    cart: cartReducer,
    career: careerReducer,
    dynamicPage: dynamicPageReducer,
    app: appReducer,
});

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

export default store;