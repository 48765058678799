import s from "./Cart.module.css";
import React from "react";
import {Link} from "react-router-dom";

const CartItems = props => {

    const cartItemsJSX = () => {
        if(props.cartData){
            return props.cartData.map((cartItem, i) => {
               const price = Math.round(cartItem.price*100)/100;

               // creating customizables string
               const customizablesArr = [];
               cartItem.customizables.forEach(custom => {
                  custom.packs.forEach(packItem => {
                     customizablesArr.push(`${packItem[`name`]}`);
                  })
               });
               const customizablesString = customizablesArr.join(', ');
                // /.

               const createEditItemData = {

               }

                return (
                   <tr className={s.itemwrp}>
                       <td className={s.itemTitleWrp}>
                          <Link className={s.itemName}>{cartItem.name} <span className={s.amount}>x{cartItem.amount}</span></Link>
                           <p className={s.itemOptions}>{customizablesString.length > 0 ? `+ ${customizablesString}` : ''}</p>
                       </td>
                       <td className={s.itemPrice}>{price} GEL</td>
                       <td className={s.itemActions}>
                           <i className="fas fa-pencil-alt" onClick={() => {
                              props.onEditCartItem(i,cartItem.amount, cartItem.customizables, cartItem.id);
                           }}></i>
                           <i className="fas fa-trash-alt" onClick={() => props.onCartItemRemoved(i)}></i>
                       </td>
                   </tr>
                )
            })
        }
        return null;
    }

    return (<table className={`w-100`}>
        <tbody>
            {cartItemsJSX()}
        </tbody>
    </table>)
}

export default CartItems;