import React, {Component, createRef} from "react";
import s from "./CategorySlider.module.css";
import SliderPagination from "../../common/SliderPagination/SliderPagination";
import Swiper from 'swiper';
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {imagePrepath} from "../../../helpers";
import {getProduct} from "../../../api/api";
import BtnPriceSide from "../../common/BtnPriceSide/BtnPriceSide";

class CategorySlider extends Component {
    swiperRef = createRef();

    state = {
        slides: [],
        activeIndex: 0,
    }

    componentWillUnmount() {
        this.setState({
            slides: [],
            activeIndex: 0,
        });

        if(this.props.categories.length > 0){
            this.swiperInstance.destroy();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.categories !== this.props.categories && this.props.categories.length > 0){
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                // Default parameters
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                
                slidesPerView: 3,
                spaceBetween: 0,
                // Responsive breakpoints
                breakpoints: {
                    1:{
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    // when window width is >= 480px
                    700: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    // when window width is >= 640px
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    }
                }
            });

            this.swiperInstance.on('slideChange', () => {
                this.setState({
                    activeIndex: this.swiperInstance.activeIndex
                })
            })
        }
    }

    categoriesToSlidesData = () => {
        return this.props.categories.map(cat => ({
            id: cat.id,
            imgLink: cat.image_name,
            name_en: cat.name_en,
            name_ge: cat.name_ge,
        }));
    }

    render(){
        const slidesArr = this.categoriesToSlidesData();


        const slidesJSX = () => {
            if(slidesArr.length > 0) {
                return slidesArr.map((el, i) => (
                      <Link key={i} to={`/shop?catId=${el.id}`} className={`swiper-slide`}>
                          <div className={s.slideInner}>
                              <img src={imagePrepath(el.imgLink)} className={s.slideImg} alt=""/>
                              <h1 className={s.title}>{el.name_ge}</h1>
                          </div>
                      </Link>
                   )
                )
            }
            return null;
        }

        return (
            <div ref={this.swiperRef} className={`swiper-container ${s.swiperContainer}`}>
                <div className="swiper-wrapper">
                    {slidesJSX()}
                </div>

                {/*<SliderPagination*/}
                {/*    posAbs={true}*/}
                {/*    light={true}*/}
                {/*    slides={slidesArr}*/}
                {/*    activeIndex={this.state.activeIndex}/>*/}

            </div>
        )
    }
}

export default CategorySlider;