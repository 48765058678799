import React, {Component} from 'react';
import s from './Loader.module.css'
import loaderGif from '../../../assets/loader.gif'

class Loader extends Component {
   render() {
      return (
         <div className={s.wrp}>
            <div className={s.loadingioSpinnerRolling}>
               <div className={s.loader}>
                  <div></div>
               </div>
            </div>
         </div>
      );
   }
}

export default Loader;