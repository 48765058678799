import React from 'react';
import logo from './logo.svg';
import 'swiper/css/swiper.min.css';
import 'nouislider/distribute/nouislider.css';
import "react-datepicker/dist/react-datepicker.css";


import './App.css';

import {
   BrowserRouter as Router,
   Switch,
   Route, Redirect, withRouter
} from 'react-router-dom';
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Shop from "./components/Shop/Shop";
import Blog from "./components/Blog/Blog";
import BlogPost from "./components/BlogPost/BlogPost";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Checkout from "./components/Checkout/Checkout";
import apiHandler from "./api/api";
import Career from "./components/Career/Career";
import SingleJob from "./components/SingleJob/SingleJob";
import DynamicPage from "./components/DynamicPage/DynamicPage";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import {getDynamicPagesAc} from "./store/reducers/dynamicPageReducer";
import {connect} from "react-redux";
import {getGeneralData, getStrings, chooseLang, initLanguages} from "./store/reducers/appReducer";
import Loader from "./components/common/Loader/Loader";
import Categories from "./components/Categories/Categories";
import withLang from "./hoc/withLang/withLang";
import {compose} from "redux";
import MessengerCustomerChat from 'react-messenger-customer-chat';

class App extends React.Component {

   generateLangChangeUrl = () => {
      const queryParams = new URLSearchParams(this.props.location.search);

      const newLang = this.props.lang === 'ge' ? 'en' : 'ge';

      queryParams.set('lang', newLang);
      return '?'+queryParams.toString();
   }

   componentDidMount() {
      this.props.getGeneralData();
      // this.props.initLanguages();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      // const queryParams = new URLSearchParams(this.props.location.search);
      // if(queryParams.get('lang') !== this.props.lang){
      //    setLang(queryParams.get('lang'));
      // }
      if(this.props.langId !== prevProps.langId){
         this.props.getGeneralData();
      }

      if(prevProps.general !== this.props.general){
         document.title = this.props.general['website_title'];
      }

   }

   render() {

      const wrpStyles = {
         minHeight: '100%',
      }

      if(!this.props.general || !this.props.languages || this.props.languages.length === 0){
         return <Loader />
      }


      return (
         <>
            <Navbar
                newLangUrl={this.generateLangChangeUrl()}
                languages={this.props.languages}
                langId={this.props.langId}
                chooseLangHandler={this.props.chooseLangHandler}
            />
            <div className={'d-flex flex-column'} style={{height: '100%'}}>

               <div className={'sss'} style={{flex: '1 0 auto'}}>
                  <Switch>
                     <Route exact path={'/'} component={Home}/>

                     <Route exact path={'/shop'} component={Shop}/>
                     <Route exact path={'/categories'} component={Categories}/>


                     <Route exact path={'/blog'} component={Blog}/>
                     <Route exact path={'/blog/:id'} component={BlogPost}/>
                     <Route exact path={'/about'} component={About}/>
                     <Route exact path={'/contact'} render={(routeProps) => <Contact {...routeProps} general={this.props.general} />}/>
                     <Route exact path={'/checkout'} component={Checkout}/>

                     <Route exact path={'/career/:id'} component={SingleJob}/>
                     <Route exact path={'/career'} component={Career}/>

                     {/* dynamic pages  */}
                     <Route path={'/pages/:pageName'} component={DynamicPage}/>
                     <Route exact path={'/pages'} component={PageNotFound}/>
                     {/* /. */}

                     {/* invoice */}
                     <Route path={'/invoice/:id'} render={() => <Redirect to={'/'}/>}/>
                     {/* /. */}

                     <Route path={'*'} component={PageNotFound}/>

                  </Switch>
               </div>



               <div style={{flexShrink: '0'}}>
                  <Footer
                      general={this.props.general}
                  />
               </div>

            </div>
         </>
      );
   }
}

const mapStateToProps = state => ({
   lang: state.app.lang,
   general: state.app.generalData,
   languages: state.app.languages,
   langId: state.app.langId,
});

const mapDispatchToProps = dispatch => ({
   getGeneralData: () => dispatch(getGeneralData()),
});


export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withLang,
)(App);
