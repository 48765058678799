import React from "react";
import {Row} from "react-bootstrap";
import BlogItem from "./BlogItem";
import withStr from "../../hoc/withStr";

const BlogItems = props => {

    if(!props.itemsArr || props.itemsArr.length === 0){
        return null;
    }


    const blogItemsJSX = () => props.itemsArr.map((el, i) => {
        const isReverse = ((i+1) % 2) === 0; // check if we are on odd blog or even; if even -> reverse is true
        return <BlogItem
           id={el.id}
           image_name={el.image_name}
           title={el.title}
           description={el.description}
           created_at={el.created_at}
           reverse={isReverse}
           getStr={props.getStr}
        />;
    });
    // id: 2
    // image_name: "2020.07.17_06.25.21_1528421048821.jpg"
    // title_en: "How to make a cheeseburger"
    // title_ge: "როგორ გავაკეთოთ ჩიზბურგერ"

    return (
        <Row>
            {blogItemsJSX()}
        </Row>
    )
}

export default withStr(BlogItems)