import React from "react";
import s from './Popup.module.css';
import popupBanner from '../../assets/images/popup-banner.png';
import Ingredients from "./Ingredients/Ingredients";
import BaseBtn from "../../components/common/NormalBtns/BaseBtn";
import Amount from "./Amount/Amount";
import IngredientsTitle from "./Ingredients/IngredientsTitle";
import Details from "./Details/Details";
import Checkbox from "../../components/common/Checkbox/Checkbox";
import withStr from "../withStr";

class Popup extends React.Component {
   render() {

      const popupTopBar = (
         <div className={s.popupImg} style={{backgroundImage: `url(${popupBanner})`}}>
            <i className={`fas fa-times ${s.closeIcon}`} onClick={this.props.onClosePopup} />
            <h3 className={s.bannerTitle}>{this.props.getStr('choose_dish')}</h3>
         </div>
      )

      const renderCustomizables = () => {
         let renderJSX;
         if(this.props.options.length !== this.props.showDropdowns.length || this.props.options.length === 0) {
            renderJSX = (null);
         } else {
            renderJSX = this.props.options.map((opt, i) => {
               return <div key={i}>
                  <IngredientsTitle
                     text={opt.name}
                     onDropdownTitleClicked={() => this.props.onDropdownTitleClicked(i)}
                     drpodownIsVisible={this.props.showDropdowns[i]}/>

                  <div ref={this.props.dropdownRefs[i]}>
                     <Ingredients
                        type={opt.type}
                        optId={opt.id}
                        packs={opt.packs}
                        onIngredientClicked={this.props.onIngredientClicked}
                        options={this.props.options}
                        selectedOptions={this.props.selectedOptions} />
                  </div>

               </div>

            })
         }
         return renderJSX;
      }

      const popupBottom = (
         <div className={s.mainWrp}>
            {renderCustomizables()}
            <Amount
               onDecrementAmount={this.props.onDecrementAmount}
               amount={this.props.amount}
               onIncrementAmount={this.props.onIncrementAmount} />
         </div>
      )

      return (
         <div ref={this.props.popupWrpRef} className={s.wrp}>
            <div className={s.darkBg} onClick={this.props.onClosePopup} />
            <div ref={this.props.popupBoxRef} className={s.popupWrp}>

               {popupTopBar}
               <Details
                  selected
                  name={this.props.name}
                  desc={this.props.desc}
                  price={this.props.price}
                  amount={this.props.amount}
                  image_link={this.props.image_link}
               />
               {popupBottom}

               <div className={s.addToCartBtnWrp}>
                  <div className={s.addToCartBtninnerWrp}>
                     <BaseBtn
                        callback={this.props.addToCartCallback}
                        className={'w-100'}
                        btnType={'Yellow'}
                        text={this.props.editMode ? this.props.getStr('save_changes') : this.props.getStr('add_to_cart')}
                        size={'lg'} />
                  </div>
               </div>

            </div>

         </div>
      )
   }
}

export default withStr(Popup);