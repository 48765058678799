import React, {Component, createRef} from "react";
import s from './Filters.module.css';
import {Link} from "react-router-dom";
import noUiSlider from 'nouislider';
import {linkWithLang} from "../../../helpers/helpers";

class Filters extends Component {

   priceRangeRef = createRef();

   componentDidUpdate(prevProps, prevState, snapshot) {

      if(
         this.props.minPriceEdge
         && this.props.maxPriceEdge
         && this.props.minPriceEdge !== this.props.maxPriceEdge
         && !this.props.initPriceRange
      ){
         if(
            this.props.minPrice
            && this.props.maxPrice
            && this.props.maxPrice > this.props.minPrice
         ){

            // this.props.changePriceRangeInit(true);
            // this.priceSliderInstance = noUiSlider.create(this.priceRangeRef.current, {
            //    start: [Math.floor(this.props.minPrice), Math.ceil(this.props.maxPrice)],
            //    connect: true,
            //    range: {
            //       'min': Math.floor(this.props.minPriceEdge),
            //       'max': Math.ceil(this.props.maxPriceEdge)
            //    }
            // });
            //
            // this.priceSliderInstance.on('change', () => {
            //    const minPrice = parseInt(this.priceSliderInstance.get()[0]);
            //    const maxPrice = parseInt(this.priceSliderInstance.get()[1]);
            //    // debugger;
            //    this.props.onPriceRangeChanged(minPrice, maxPrice);
            // });

         }
      }


      if(
         (this.props.minPriceEdge !== prevProps.minPriceEdge || this.props.maxPriceEdge !== prevProps.maxPriceEdge)
         && this.props.initPriceRange
      ){
         if(this.props.minPriceEdge && this.props.maxPriceEdge && this.props.minPriceEdge !== this.props.maxPriceEdge) {
            this.props.onPriceRangeChanged(Math.floor(this.props.minPriceEdge), Math.ceil(this.props.maxPriceEdge));
            // new options for price range slider
            this.priceSliderInstance.updateOptions({
               start: [Math.floor(this.props.minPriceEdge), Math.ceil(this.props.maxPriceEdge)],
               range: {
                  'min': Math.floor(this.props.minPriceEdge),
                  'max': Math.ceil(this.props.maxPriceEdge)
               }
            }, true);

         }
      }
   }

   render(){

      const generateNewPath = (catId) => {
         const searchParams = new URLSearchParams(window.location.search);
         searchParams.delete('minPrice')
         searchParams.delete('maxPrice');
         searchParams.delete('search');
         searchParams.delete('sort');
         searchParams.set('catId', catId);
         return '?catId='+catId;
      }

      const renderPriceRange = () => {
         return null;

         // let showClass = 'd-none';
         // // debugger;
         // if(this.props.minPriceEdge
         //    && this.props.maxPriceEdge
         //    && this.props.minPriceEdge !== this.props.maxPriceEdge
         // ){
         //    showClass = '';
         // }
         //
         // return(
         //    <div className={`${s.priceRangeWrp} ${showClass}`}>
         //       <div className={s.priceRange} ref={this.priceRangeRef}></div>
         //       <div className={'mt-4'}>
         //          <p className={'text-center text-light'}>
         //             {Math.floor(this.props.minPrice)} GEL - {Math.ceil(this.props.maxPrice)} GEL
         //          </p>
         //       </div>
         //    </div>
         // )
      }

      return (
         <div className={s.wrp}>
            <ul className={s.catFilterWrp}>
               {/*<li className={`${s.catFilterItem} ${!this.props.catId ? s.active : ''}`}>*/}
               {/*   <Link to={'/shop'} >ყველა</Link>*/}
               {/*</li>*/}
               {this.props.categories.map(el =>
                  {
                     const newPath = generateNewPath(el.id);
                     return (
                        <li className={`${s.catFilterItem} ${this.props.catId === el.id ? s.active : ''}`}>
                           <Link to={linkWithLang(newPath)} >{el.name}</Link>
                        </li>
                     )
                  }
               )}
            </ul>

            {renderPriceRange()}

         </div>
      );
   }
}

export default Filters;