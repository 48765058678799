import store from "../store/redux-store";

export const linkWithLang = (link) => {
    // debugger;
    const langId = store.getState().app.langId;
    if (langId) {
        link = link.split('#');
        let queryArr = link[0].split('?');
        let queryParamsString = queryArr[1] ? '?'+queryArr[1] : '';
        let queryParams = new URLSearchParams(queryParamsString);
        queryParams.set('lang', langId);

        // const partOne = link[0];
        // const partTwo = link[1] ? '#'+link[1] : '';
        return queryArr[0] + '?' + queryParams.toString();
    }
}

window.linkIwthLang = linkWithLang;