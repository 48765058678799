import React, {createRef} from 'react';
import Swiper from 'swiper';
import s from './HomeSlider.module.css';

import {Container, Col, Row} from "react-bootstrap";
import ContentBoxSlider from './ContentBoxSlider';
import {imagePrepath} from "../../../helpers";

class HomeSlider extends React.Component {
    swiperRef = createRef();
    state={
        currentSlideIndex: 0,
    }

    // lifecycle methods
    componentWillUnmount(){
        this.setState({
            currentSlideIndex: 0,
        });

        if(this.props.slides.length > 0){
            this.swiperInstance.destroy();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.currentSlideIndex !== prevState.currentSlideIndex){
            const swiperInstance = this.swiperInstance;
            swiperInstance.slideTo(this.state.currentSlideIndex);
        }

        if(prevProps.slides !== this.props.slides && this.props.slides.length > 0){
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            });
            const swiperInstance = this.swiperInstance;

            swiperInstance.slideTo(this.state.currentSlideIndex);
            swiperInstance.on('slideChange', () => {
                this.setCurrentSlide(swiperInstance.activeIndex);
            });
        }
    }

    // /.

    setCurrentSlide = (newSlideIndex) => {
        this.setState({
            currentSlideIndex:  newSlideIndex,
        });
    }

    render() {
        if(!this.props.slides){
            return null;
        }

        const slidesJSX = () => {
            return this.props.slides.map(slide => {
                if(slide.url){
                    return <a href={slide.url || '#'} target={'_blank'} className={`swiper-slide ${s.swiperSlide}`}
                       key={slide.id}
                       style={{backgroundImage: `url(${imagePrepath(slide.image_name)})`}} />
                }

                return (
                    <div className={`swiper-slide ${s.swiperSlide}`}
                         key={slide.id}
                         style={{backgroundImage: `url(${imagePrepath(slide.image_name)})`}} />
                )
            })
        }

        return(
            <div className={s.sliderWrp}>
                <div className={`w-100 h-100 ${s.bgGray}`}>
                    <Container className={'h-100'}>
                        <div className={'d-flex h-100 align-items-center'}>

                            <div ref={this.swiperRef} className={`swiper-container ${s.swiperContainer}`}>
                                <div className="swiper-wrapper">
                                    {slidesJSX()}
                                </div>
                            </div>

                            <ContentBoxSlider
                                onRenderPopupHanlder={this.props.onRenderPopupHanlder}
                                currentSlideIndex={this.state.currentSlideIndex}
                                setCurrentSlide={this.setCurrentSlide}
                                slides={this.props.slides}
                                getStr={this.props.getStr}
                            />
                        </div>

                    </Container>
                </div>
            </div>
        )
    }
}

export default HomeSlider;