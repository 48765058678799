import React from "react";
import s from './BlogItem.module.css';
import {Col, Row} from "react-bootstrap";
import BaseBtn from "../common/NormalBtns/BaseBtn";
import {imagePrepath} from "../../helpers";
import {Link} from "react-router-dom";
import {linkWithLang} from "../../helpers/helpers";


const BlogItem = props => {
    return (
        <Col xs={12} className={s.wrp}>
            <Link to={linkWithLang(`/blog/${props.id}`)} className={'row'}>
                <Col md={7} className={`${props.reverse ? s.reverseImgCol : s.normImgCol}`}>
                    <div className={s.imgWrp}>
                        <img src={imagePrepath(props.image_name)} className={s.img} alt=""/>
                    </div>
                </Col>

                <Col md={5} className={`${props.reverse ? s.reverseTextCol : s.normTextCol}`}>
                    <div className={`${s.textWrp} w-100 h-100`}>
                        <div className={s.date}>{props.created_at.substr(0, 10)}</div>
                        <h3 className={s.title}>{props.title}</h3>
                        <p className={s.text}>{props.description}</p>
                        {/*<BaseBtn*/}
                        {/*   linkPath={`/blog/${props.id}`}*/}
                        {/*   btnType={'Gray'}*/}
                        {/*   text={props.getStr('see_more')} />*/}
                    </div>
                </Col>
            </Link>
        </Col>
    )
}

export default BlogItem;