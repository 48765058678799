import React, {Component} from "react";
import s from './Blog.module.css';
import bannerImg from "../../assets/images/banner1.jpg";
import TitleBanner from "../common/TitleBanner/TitleBanner";
import BlogItems from "../BlogItems/BlogItems";
import {Container} from "react-bootstrap";
import BaseBtn from "../common/NormalBtns/BaseBtn";
import {connect} from "react-redux";
import {fetchBlogPosts} from "../../store/reducers/blogPageReducer";
import Loader from "../common/Loader/Loader";
import withStr from "../../hoc/withStr";

class Blog extends Component{
   componentDidMount() {
      this.props.fetchBlogPosts();
      window.scroll(0,0);
   }

   render(){

      if(!this.props.blogPosts || this.props.blogPosts.length === 0){
         return <Loader />
      }

      return (
         <div>
            <TitleBanner imageURL={bannerImg} text={this.props.getStr('blog')} />
            <div className={s.wrp}>
               <Container>
                  <BlogItems itemsArr={this.props.blogPosts} />
                  {/*<div className={'text-center'}>*/}
                  {/*   <BaseBtn className={'px-5'} btnType={'Gray'} text={'Load More'} size={'md'} />*/}
                  {/*</div>*/}
               </Container>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => ({
   blogPosts: state.blogPage.blog,
});

const mapDispatchToProps = dispatch => ({
   fetchBlogPosts: () => dispatch(fetchBlogPosts())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStr(Blog));