import React, {Component} from 'react';
import s from './Categories.module.css';
import bannerImg from "../../assets/images/banner1.jpg";
import TitleBanner from "../common/TitleBanner/TitleBanner";
import {Col, Container, Row} from "react-bootstrap";
import {connect} from "react-redux";
import withStr from "../../hoc/withStr";
import {getCategories} from "../../store/reducers/shopPageReducer";
import Loader from "../common/Loader/Loader";
import {Link} from "react-router-dom";
import {imagePrepath} from "../../helpers";
import {linkWithLang} from "../../helpers/helpers";

class Categories extends Component {

    componentDidMount() {
        this.props.getCategories();
    }

    render() {

        if (!this.props.categories || this.props.categories.length === 0) {
            return <Loader/>
        }

        const categoriesJSX = () => {
            return this.props.categories.map(cat => {
                // id: 2
                // name_en: "Pizza"
                // name_ge: "პიცა"
                return (
                    <Col lg={4} className={'mb-4'}>
                        <Link to={linkWithLang(`/shop?catId=${cat.id}`)}>
                            <div className={s.slideInner}>
                                <img src={imagePrepath(cat.image_name)} className={s.slideImg} alt=""/>
                                <h2 className={s.title}>{cat.name}</h2>
                            </div>
                        </Link>
                    </Col>
                )
            });
        }

        return (
            <div>
                <TitleBanner imageURL={bannerImg} text={this.props.getStr('categories')}/>
                <div className={s.wrp}>
                    <Container>
                        <Row>
                            {categoriesJSX()}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    categories: state.shop.categories,
})

const mapDispatchToProps = dispatch => ({
    getCategories: () => dispatch(getCategories())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStr(Categories));