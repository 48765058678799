import React from "react";
import s from './Product.module.css';
import {Col} from "react-bootstrap";
import BtnPriceSide from "../common/BtnPriceSide/BtnPriceSide";

const Product = props => {
    // id: 2,
    // name: 'Beef Stake #2',
    // desc: 'Beef, Carrot, Cheese, French Fries',
    // price: '15.99',
    // imageLink: 'http://assets.suelo.pl/soup/img/products/product-pizza.jpg',

   const productInsides = (
      <>
         <div className={s.imgWrp}>
            <img src={props.imageLink} className={s.img} alt=""/>
         </div>
         <div className={s.wrp}>
            <div>
               <p className={s.name}>{props.name}</p>
               <p className={s.desc}>{props.desc}</p>
            </div>
            <BtnPriceSide
               full
               btnType={'Yellow'}
               text={props.getStr('add_to_cart')}
               price={props.price}
               callback={props.callback}
            />
         </div>
      </>
   )

   if(props.sizeMixins) {
      return (
         <Col {...props.sizeMixins} className={`mb-4 d-flex flex-column ${props.className}`}>
            {productInsides}
         </Col>
      )
   }

   return <Col md={6} lg={4} className={`mb-4 d-flex flex-column ${props.className}`}>
      {productInsides}
   </Col>


}

export default Product;