import React from "react";
import s from "./Amount.module.css";

const Amount = props => {
   return (
      <div className={s.amntWrp}>
         <div className={s.amntBtn} onClick={props.onDecrementAmount}>
            <i className="fas fa-minus"></i>
         </div>
         <div className={s.amnt}>{props.amount}</div>
         <div className={s.amntBtn} onClick={props.onIncrementAmount}>
            <i className="fas fa-plus"></i>
         </div>
      </div>
   )
}

export default Amount;