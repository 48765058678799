import React from "react";
import s from './Contact.module.css';
import {imagePrepath} from "../../helpers";
import TitleBanner from "../common/TitleBanner/TitleBanner";
import bannerImg from "../../assets/images/banner1.jpg";
import {Col, Container, Row} from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import mapMarkerImg from '../../assets/images/marker.svg'
import withStr from "../../hoc/withStr";
import ContactMap from "./ContactMap";

const AnyReactComponent = ({ text }) => {
   return (
      <div>
         <img src={mapMarkerImg} className={s.marker} alt=""/>
      </div>
   );
};

const Contact = props => {

   const lat = 41.7269371;
   const lng = 44.7636518

   return (
      <div>
         <TitleBanner imageURL={bannerImg} text={props.getStr('nav_contact')} />
         <Container className={s.wrp}>
            <Row>

               <Col md={'6'}>
                  <h3 className={`${s.title}`}>{props.general.address}</h3>
                  <ul className={s.contactData}>
                     <li className={s.contactItem}>{props.getStr('phone')}: <a href={'tel:'+props.general.phone} >{props.general.phone}</a></li>
                     <li className={s.contactItem}>{props.getStr('email')}: <a href={'mailto:'+props.general.email} >{props.general.email}</a></li>
                  </ul>
                  <div className={'d-flex mt-3'}>
                     <a href={props.general.facebook_link} target='_blank' className={s.iconWrp}>
                        <i className="fab fa-facebook-f"></i>
                     </a>

                     <a href={props.general.instagram_link} target='_blank' className={s.iconWrp}>
                        <i className="fab fa-instagram"></i>
                     </a>
                  </div>
               </Col>

               <Col md={'6'}>
                  <div className={s.mapWrp}>
                     <ContactMap
                        mapMarker={{
                           centerX: 41.7269371,
                           centerY: 44.7636518,
                        }}
                        zoom={15}
                     />
                  </div>
               </Col>
            </Row>
         </Container>
      </div>
   )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default withStr(Contact);