import s from "./OfferTextBox.module.css";
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import React from "react";
import {getProduct} from "../../../api/api";
import BtnPriceSide from "../../common/BtnPriceSide/BtnPriceSide";

const OfferTextBox = props => {

    const addToCartBtn = () => {
        const callback = () => {
            getProduct(props.prodId)
               .then(res => {
                   console.log(res);
                   props.onRenderPopupHanlder(
                      res.id,
                      res.name_en,
                      res.name_ge,
                      res.description_en,
                      res.description_ge,
                      res.price,
                   );
               });

        }

        return (
           <div className={'mt-4'}>
               <BtnPriceSide
                  price={props.price}
                  text={props.getStr('add_to_cart')}
                  callback={callback}
               />
           </div>
        )
    }

    return (<div className={s.textBox}>
        <h1 className={s.title}>{props.slideTitle}</h1>
        <p className={s.text}>{props.slideText}</p>
        {/*list*/}

        {/* /.*/}
        <div className={'mt-4'}>
            {props.prodId
               ? addToCartBtn()
               : null
            }
        </div>
    </div>)
}

export default OfferTextBox;