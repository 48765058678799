import React, {Component} from "react";
import s from './BlogPost.module.css';
import bannerImg from "../../assets/images/banner1.jpg";
import TitleBanner from "../common/TitleBanner/TitleBanner";
import {clearSinglePostData, fetchBlogPosts, fetchSinglePost} from "../../store/reducers/blogPageReducer";
import {connect} from "react-redux";
import {imagePrepath} from "../../helpers";
import {Container} from "react-bootstrap";
import Loader from "../common/Loader/Loader";

class BlogPost extends Component{

   componentDidMount() {
      const blogPostId = this.props.match.params.id;
      this.props.fetchSinglePost(blogPostId);
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      console.log(this.props);
      window.scrollTo(0,0);
   }

   componentWillUnmount() {
      this.props.clearSignlePost();
   }

   render(){

      if(!this.props.singlePostData || Object.keys(this.props.singlePostData).length === 0){
         return <Loader />
      }

      const createBlogContentMarkup = () => {
         return {__html: this.props.singlePostData.content};
      }

      return (
         <div className={s.wrp}>
            <Container>

               <TitleBanner
                  className={`${s.bannerWrp}`}
                  imageURL={imagePrepath(this.props.singlePostData.image_name)}
                  text={''}
               />

               <div className={s.blogWrp}>
                  <p className={s.meta}>{this.props.singlePostData.created_at.substr(0, 10)}</p>
                  <h2 className={s.title}>{this.props.singlePostData.title}</h2>
                  <div className={s.content} dangerouslySetInnerHTML={createBlogContentMarkup()}></div>
               </div>

            </Container>



         </div>
      )
   }
}

const mapStateToProps = state => ({
   singlePostData: state.blogPage.singlePostData,
});

const mapDispatchToProps = dispatch => ({
   fetchSinglePost: (id) => dispatch(fetchSinglePost(id)),
   clearSignlePost: () => dispatch(clearSinglePostData())
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogPost);