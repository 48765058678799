import React from "react";
import s from './Footer.module.css';
import {Col, Container, Row, Form} from "react-bootstrap";

import logoUrl from '../../assets/images/logo.png';
import withStr from "../../hoc/withStr";
import BaseBtn from "../common/NormalBtns/BaseBtn";

const Footer = props => {
    return (
        <div className={s.wrp}>
            <Container>
                <Row>
                    <Col md={6}>
                        <Row className={'justify-content-center'}>
                            <Col xs={8} md={6} lg={5}>
                                <img src={logoUrl} className={'w-100'} alt=""/>
                            </Col>
                            <Col xs={12} lg={7}>
                                <div className={s.textItemWrp}>
                                    <h4>{props.getStr('phone_field')}</h4>
                                    <a href={'tel:'+props.general.phone}>{props.general.phone}</a>
                                </div>

                                <div className={s.textItemWrp}>
                                    <h4>{props.getStr('address_field')}</h4>
                                    <p>{props.general.address}</p>
                                </div>

                                <div className={s.textItemWrp}>
                                    <h4>{props.getStr('email_field')}</h4>
                                    <a href={'mailto:'+props.general.email}>{props.general.email}</a>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <div className={`${s.textItemWrp}`}>
                            <h4>{props.getStr('newsletter')}</h4>
                            <Form className={'col-12'}>
                                <Form.Group controlId="formBasicEmail">
                                    <div className="row align-items-stretch">
                                        <Form.Control className={'col-12 col-sm h-auto mb-3'} type="email" placeholder={props.getStr('email_field')} />
                                        <BaseBtn
                                            btnType={'Yellow'}
                                            className={'px-4 ml-sm-3'}
                                            size={'md'}
                                            text={props.getStr('submit')}
                                        />
                                    </div>
                                </Form.Group>
                            </Form>
                        </div>

                        <div className={s.textItemWrp}>
                            <h4>{props.getStr('social_footer')}</h4>
                            <div className={'d-flex mt-3'}>
                                <a href={props.general.facebook_link} target="_blank" className={s.iconWrp}>
                                    <i className="fab fa-facebook-f"></i>
                                </a>

                                <a href={props.general.instagram_link} target="_blank" className={s.iconWrp}>
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default withStr(Footer);