import React from "react";
import s from './Details.module.css';
import {Col, Row} from "react-bootstrap";
import {imagePrepath} from "../../../helpers";

const Details = props => {
   return (
      <div >
         <Row className={`${s.wrp} d-flex justify-content-between mx-0`}>

            <Col xs={6}>
               <div className={s.imageHeight}>
                  <div className={s.innerWrp}>
                     <img src={imagePrepath(props.image_link)} className={s.img} alt=""/>
                  </div>
               </div>
            </Col>

            <Col xs={6}>
               <h5 className={s.title}>{props.name}</h5>
               <p className={s.desc}>{props.desc}</p>
               <p className={`${s.price} h5 mb-0 mt-2`}>{ (props.price * props.amount).toFixed(2) } GEL</p>
            </Col>
         </Row>
      </div>
   )
}

export default Details;