import React, {Component, createRef} from "react";
import {Link} from "react-router-dom";
import YellowBtn from "../../common/NormalBtns/YellowBtn/YellowBtn";
import {gsap} from "gsap";

import s from "./MobileNavbarSidebar.module.css";
import GreenLogoUrl from "../../../assets/images/logo.png";
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import {linkWithLang} from "../../../helpers/helpers";
import {Dropdown} from "react-bootstrap";

class MobileNavbarSidebar extends Component {
    sidebarWrpRef = createRef();
    sidebarRef = createRef();

    componentDidMount() {
        const sidebarWrpRef = this.sidebarWrpRef.current;
        const sidebarRef = this.sidebarRef.current;

        gsap.set(sidebarWrpRef, {display: 'none', opacity: '0'});
        gsap.set(sidebarRef, {x: '-100%'});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const sidebarWrpRef = this.sidebarWrpRef.current;
        const sidebarRef = this.sidebarRef.current;
        if (this.props.showSidebar !== prevProps.showSidebar) {
            if (this.props.showSidebar) {
                // show wrapper
                gsap.set(sidebarWrpRef, {display: 'block'});
                gsap.to(sidebarWrpRef, {opacity: '1', duration: 0.3});
                // slide in sidebar
                gsap.to(sidebarRef, {x: 0, duration: 0.3});
            } else {
                // slide in sidebar
                gsap.to(sidebarRef, {
                    x: '-100%', duration: 0.3, onComplete: () => {
                        // show wrapper
                        gsap.to(sidebarWrpRef, {
                            opacity: '0', duration: 0.3, onComplete: () => {
                                gsap.set(sidebarWrpRef, {display: 'none'});
                            }
                        });
                    }
                });
            }
        }
    }

    render() {
        const chosenLanguage = this.props.languages.find(el => el.id === this.props.langId).name;
        const otherLanguages = this.props.languages.map(el => {
            if (el.id !== this.props.langId && el.enabled === 1) {
                return <div className={'dropdown-item'}
                            onClick={() => this.props.chooseLangHandler(el.id)}>{el.name}</div>
            }
        })

        return (
            <div ref={this.sidebarWrpRef} className={s.mobileNavbarSidebarWrp}>
                <div onClick={this.props.onSidebarCloseBtnClicked} className={s.sidebarDarkOverlay}></div>


                <div ref={this.sidebarRef} className={s.mobileNavbarSidebar}>

                    <i className={`fas fa-times ${s.sidebarCloseBtn}`}
                       onClick={this.props.onSidebarCloseBtnClicked}></i>

                    <img src={GreenLogoUrl} className={s.sidebarLogo} alt=""/>

                    <div>

                        <Link
                            onClick={this.props.onSidebarCloseBtnClicked}
                            to={linkWithLang('/')}
                            className={s.navItem}>{this.props.getStr('nav_home')}</Link>

                        <div className={s.navItemMargin}>
                            <BaseBtn
                                callback={this.props.onSidebarCloseBtnClicked}
                                btnType={'Yellow'}
                                className={'w-100'}
                                text={this.props.getStr('nav_shop')}
                                linkPath={linkWithLang('/shop')}
                            />
                        </div>

                        <Link
                            onClick={this.props.onSidebarCloseBtnClicked}
                            to={linkWithLang('/blog')}
                            className={s.navItem}>{this.props.getStr('nav_blog')}</Link>

                        <Link
                            onClick={this.props.onSidebarCloseBtnClicked}
                            to={linkWithLang('/career')}
                            className={s.navItem}>{this.props.getStr('nav_career')}</Link>

                        {this.props.pageNames.map(el => {
                            return <Link
                                onClick={this.props.onSidebarCloseBtnClicked}
                                to={linkWithLang(`/pages/${el.path}`)}
                                className={s.navItem}>{el.name}</Link>
                        })}

                        <Link
                            onClick={this.props.onSidebarCloseBtnClicked}
                            to={linkWithLang('/contact')}
                            className={s.navItem}>{this.props.getStr('nav_contact')}</Link>

                        <div className={`${s.navItem} border-none`}>
                            <Dropdown className={'mt-3'}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {chosenLanguage}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {otherLanguages}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default MobileNavbarSidebar;