import React, {Component, createRef} from "react";
import s from './FeaturedProducts.module.css';
import {Container} from "react-bootstrap";
import Products from "../../Products/Products";
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import Swiper from "swiper";
import {Link} from "react-router-dom";
import {imagePrepath} from "../../../helpers";
import Product from "../../Products/Product";
import {linkWithLang} from "../../../helpers/helpers";


class FeaturedProducts extends Component {

    swiperRef = createRef();

    state = {
        activeIndex: 0,
    }

    componentWillUnmount() {
        this.setState({
            activeIndex: 0,
        });

        if(this.props.featuredProducts.length > 0){
            this.swiperInstance.destroy();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.featuredProducts !== this.props.featuredProducts && this.props.featuredProducts.length > 0){
            this.swiperInstance = new Swiper(this.swiperRef.current, {
                // Default parameters
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },

                slidesPerView: 3,
                spaceBetween: 0,
                // Responsive breakpoints
                breakpoints: {
                    1:{
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    // when window width is >= 480px
                    700: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    // when window width is >= 640px
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    }
                }
            });

            this.swiperInstance.on('slideChange', () => {
                this.setState({
                    activeIndex: this.swiperInstance.activeIndex
                })
            })
        }
    }

    productsToSlidesData = () => {
        return this.props.featuredProducts.map(prod => {
            return {
                id: prod.id,
                name: prod.name,
                desc: prod.description,
                price: prod.price,
                imageLink: imagePrepath(prod.image_name),
                callback: () => this.props.onRenderPopup(prod.id, prod.name, prod.description, prod.price, prod.image_name ),
                getStr: this.props.getStr,
                sizeMixins: {col: '12'},
                className: 'h-100'
            }
        });
    }

    render() {
        const slidesArr = this.productsToSlidesData();

        const swiperJSX = () => {
            if(slidesArr.length > 0) {
                return slidesArr.map((el, i) => (
                        <div className={`swiper-slide ${s.slide}`}>
                           <Product {...el} />
                        </div>
                    )
                )
            }
            return null;
        }

        return (
            <div className={s.wrp}>
                <Container>
                    <h1 className={s.title}>{this.props.getStr('featured_products')}</h1>
                    {/*<Products*/}
                    {/*    addCartItem={this.props.addCartItem}*/}
                    {/*    onRenderPopup={this.props.onRenderPopup}*/}
                    {/*    productsArr={this.props.featuredProducts}/>*/}

                    <div ref={this.swiperRef} className={`swiper-container`}>
                        <div className="swiper-wrapper">
                            {swiperJSX()}
                        </div>
                    </div>

                </Container>

                <div className={'text-center mt-3'}>
                    <BaseBtn
                        className={'px-5'}
                        btnType={'Gray'}
                        text={this.props.getStr('all_products')}
                        linkPath={linkWithLang('/shop')}
                        size={'md'}/>
                </div>
            </div>
        )
    }
}

export default FeaturedProducts;