import { useLayoutEffect } from 'react';

const ScrollToTop = () => {
   useLayoutEffect(() => {
      window.scroll(0, 0);
   }, []);

   return null;
};

export default ScrollToTop;