import React from 'react';
import {Col} from "react-bootstrap";
import s from "./TextInput.module.css";
import PropTypes from 'prop-types';
import Label from "../Label/Label";

const TextInput = props => {
   const InputComp = props.isTextarea ? 'textarea' : 'input';
   return (
      <Col {...props.bootstrapSizeConfig} >
         <Label
            label={props.label}
            name={props.name}
            error={props.error}
            touched={props.touched}
         />
         <InputComp
            id={props.name}
            className={`form-control ${s.input}`}
            type="text"
            name={props.name}
            onChange={(e) => props.changeHandler(e.target.value)}
            onBlur={(e) => props.blurHandler(e.target.value)}
            value={props.value}
         />
      </Col>
   )
};

export default TextInput;


TextInput.propTypes = {
   name: PropTypes.string.isRequired,
   label: PropTypes.string.isRequired,
   value: PropTypes.string.isRequired,
   error: PropTypes.string.isRequired,
   touched: PropTypes.bool.isRequired,

   changeHandler: PropTypes.func.isRequired,
   blurHandler: PropTypes.func.isRequired,

   bootstrapSizeConfig: PropTypes.object,
   isTextarea: PropTypes.bool,

}
