//action constants
import apiHandler from "../../api/api";

const CLOSE_POPUP = 'CLOSE_POPUP';
const SHOW_POPUP = 'SHOW_POPUP';
const SET_OPTIONS = 'SET_OPTIONS';
const ADD_INGREDIENT = 'ADD_INGREDIENT';
const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
const INCREMENT_AMOUNT = 'INCREMENT_AMOUNT';
const DECREMENT_AMOUNT = 'DECREMENT_AMOUNT';
const SET_SELECTED_OPTIONS = 'SET_SELECTED_OPTIONS';


//initial state
const initState = {
    id: null,
    // showPopup: false,
    options: [],
    selectedOptions: [], // option ids from selected options
    amount: 1,
    price: null,
    // name: {
    //    name_en: '',
    //    name_ge: '',
    // },
    // desc: {
    //    desc_en: '',
    //    desc_ge: '',
    // },
    name: '',
    desc: '',
    product_image: null,
    editMode: false,
}

//reducer
const popupReducer = (state = initState, action) => {
    switch (action.type) {
        case CLOSE_POPUP:
            return {
                ...state,
                id: null,
                // showPopup: false,
                // name: {
                //    name_en: '',
                //    name_ge: '',
                // },
                // desc: {
                //    desc_en: '',
                //    desc_ge: '',
                // },
                name: '',
                desc: '',
                price: null,
                image_link: null,

                options: [],
                selectedOptions: [],
                editMode: false,
                index: undefined,
            };
        case SHOW_POPUP:
            return {
                ...state,
                id: action.id,
                // showPopup: true,
                // name: {
                //    name_en: action.name_en,
                //    name_ge: action.name_ge,
                // },
                // desc: {
                //    desc_en: action.desc_en,
                //    desc_ge: action.desc_ge,
                // },
                name: action.name,
                desc: action.desc,
                price: action.price,
                image_link: action.image_link,

                amount: action.amount,
                editMode: action.editMode,
                product_image: action.product_image,
                index: action.index,
            }
        case SET_OPTIONS:
            return {
                ...state,
                options: action.options,
            }
        case SET_SELECTED_OPTIONS:
            return {
                ...state,
                selectedOptions: action.selectedOptions,
            }
        case ADD_INGREDIENT:
            //generate new array based on type: 0 - radio, 1-checkbox
            //key reason for this is that when we select different radio others have to be deselected on fake checkbox and from state
            const genNewArr = () => {
                let newArr = state.selectedOptions.filter(opt => {
                    if (action.optType === 0) {
                        return opt.optId !== action.optObj.optId;
                    }
                    return true;
                });

                newArr.push(action.optObj);
                return newArr;
            }
            // /.

            return {
                ...state,
                selectedOptions: genNewArr(),
            };
        case REMOVE_INGREDIENT:
            // debugger;
            return {
                ...state,
                selectedOptions: state.selectedOptions.filter(opt => {
                    // filter returns array elements whose optId and packId don't match
                    return !(opt.optId === action.optObj.optId && opt.packId === action.optObj.packId)
                }),
            };
        case INCREMENT_AMOUNT:
            return {
                ...state,
                amount: state.amount + 1
            }
        case DECREMENT_AMOUNT:
            return {
                ...state,
                amount: state.amount - 1 !== 0 ? state.amount - 1 : 1,
            }

        default:
            return state;
    }
}

export default popupReducer;


//action creators
export const closePopup = () => {
    return {type: CLOSE_POPUP};
}

const showPopup = (prod_id, name, desc, price, amount, editMode, index, image_link) => {
    const dataObj = {
        id: prod_id,
        name,
        desc,
        price,
        amount,
        editMode,
        index,
        image_link
    };

    return {type: SHOW_POPUP, ...dataObj};
}

const setOptions = (options) => {
    return {type: SET_OPTIONS, options}
}

const renderPopupInner = (
    prod_id,
    name,
    desc,
    price,
    selectedOptions = [],
    amount = 0,
    editMode = false,
    index = undefined,
    dispatch,
    image_link
) => {
    dispatch(showPopup(prod_id, name, desc, price, amount, editMode, index, image_link));
    apiHandler('getCustomizables', {Productid: prod_id})
        .then(res => {
            dispatch(setOptions(res.customizables));
            // debugger;
            dispatch(setSelectedOptions(selectedOptions))
        });
}

export const renderPopup = (
    prod_id,
    name,
    desc,
    price,
    image_link,
    selectedOptions = [],
    amount = 1,
    editMode = false,
    index = undefined
) => {
    return (dispatch) => {
        renderPopupInner(
            prod_id,
            name,
            desc,
            price,
            selectedOptions = [],
            amount,
            editMode,
            index,
            dispatch,
            image_link,
        );
    }
}

const setSelectedOptions = (setSelectedOptions) => {
    return {type: SET_SELECTED_OPTIONS, selectedOptions: setSelectedOptions}
}

export const onAddIngredient = (optObj, optType) => {
    return {type: ADD_INGREDIENT, optObj, optType}
}

export const onRemoveIngredient = (optObj) => {
    return {type: REMOVE_INGREDIENT, optObj}
}

export const incrementAmount = () => {
    return {type: INCREMENT_AMOUNT}
}

export const decrementAmount = () => {
    return {type: DECREMENT_AMOUNT}
}

const reverseTransformSelectedOptions = (selectedOptions) => {
    const newArr = [];
    selectedOptions.forEach((el) => {
        el.packs.forEach((pack) => {
            newArr.push({
                optId: el.id,
                packId: pack.id,
            })
        })
    });
    return newArr;
}

export const editCartItemPopup = (index, amount, selectedOptions, id, callback = undefined) => {
    return dispatch => {

        const newSelectedOptions = reverseTransformSelectedOptions(selectedOptions);
        apiHandler('getProduct', {Productid: id})
            .then(res => {
                // debugger
                renderPopupInner(
                    res.id,
                    res.name,
                    res.description,
                    res.price,
                    newSelectedOptions,
                    amount,
                    true,
                    index,
                    dispatch,
                    res.image_name,
                )

                if (callback !== undefined) {
                    callback()
                }
            })
    }
}