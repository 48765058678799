//action constants
import CallAPI from "restaurants_apimanager";
import apiHandler from "../../api/api";
import {getDynamicPagesAc} from "./dynamicPageReducer";

const INIT_HOMEPAGE = 'INIT_HOMEPAGE';

//initial state
const initState = {
    featuredProducts: [],
    categories: [],
    specialOffers: [],
    slides: [],
    blog: [],
    init: false,
}

//reducer
const homePageReducer = (state = initState, action) => {
    switch (action.type) {
        case INIT_HOMEPAGE:
            return {
                ...state,
                featuredProducts: action.featuredProducts,
                categories: action.categories,
                specialOffers: action.specialOffers,
                slides: action.slides,
                blog: action.blog,
                init: true,
            }
        default:
            return state;
    }
}

export default homePageReducer;


//action creators
// export const follow = (userId) => ({type: FOLLOW, userId: userId});


//thunk creators
export const initHomepage = () => {
    return dispatch => {
        return Promise.all([
            apiHandler('getCategories'),
            apiHandler('getFeaturedProducts'),
            apiHandler('getSliderItems'),
            apiHandler('getSpecialOffers'),
            apiHandler('getBlogPosts'),
           ]
        )
        .then(res => {
            const dataObj = {
                categories: res[0],
                featuredProducts: res[1],
                slides: res[2],
                specialOffers: res[3],
                blog: res[4],
            }

            dispatch({type: INIT_HOMEPAGE, ...dataObj });



            // console.log(res);
        })
    }
}

