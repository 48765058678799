import apiHandler from "../../api/api";

//action constants
const CHOOSE_LANG = 'CHOOSE_LANG';
const SET_GENERAL_DATA = 'SET_GENERAL_DATA';
const SET_STRINGS = 'SET_STRINGS';
const INIT_LANGUAGES = 'INIT_LANGUAGES';

//initial state
const initState = {
   langId: 0,
   languages: [],
   generalData: null,
   stings: {}
}

//reducer
const appReducer = (state = initState, action) => {
   switch (action.type) {
      case SET_GENERAL_DATA:
         return {
            ...state,
            generalData: action.generalData
         }
      case SET_STRINGS:
         return {
            ...state,
            strings: action.strings
         }
      case INIT_LANGUAGES:
         return {
            ...state,
            languages: action.languages,
            langId: action.langId || 1,
         }
      case CHOOSE_LANG:
         return {
            ...state,
            langId: action.langId,
         }
      default:
         return state;
   }
}

export default appReducer;


//action creators
// export const follow = (userId) => ({type: FOLLOW, userId: userId});
export const chooseLang = (langId) => {
   return {
      type: CHOOSE_LANG,
      langId: langId,
   }
}

//thunks
export const initLanguages = (langId) => {
   return dispatch => {
      return apiHandler('getLanguages')
          .then(res => {
             return dispatch({
                type: INIT_LANGUAGES,
                languages: res,
                langId: langId,
             })
          })
   }
}

export const getGeneralData = () => {
   return dispatch => {
      apiHandler('getGeneralInfo')
          .then(res => {
             dispatch({
                type: SET_GENERAL_DATA,
                generalData: res,
             })
          })
   }
}

export const getStrings = (langId) => {
   return dispatch => {
      return apiHandler('getFrontendStrings', {Languageid: langId})
          .then(res => {
             dispatch({
                type: SET_STRINGS,
                strings: res,
             })
          })
   }
}

