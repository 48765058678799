import React from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Col} from "react-bootstrap";
import s from './ContactMap.module.css';


class ContactMap extends React.PureComponent {


    render() {

        const markerJSX = () => {
            if (this.props.mapMarker.centerX && this.props.mapMarker.centerY) {
                return <Marker position={{lat: this.props.mapMarker.centerX, lng: this.props.mapMarker.centerY}}/>
            }
            return null
        }

        return (
            <div className={`position-relative ${s.mapWrp}`}>
                <Map
                    onClick={this.onMapClicked}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative'
                    }}
                    containerStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0'
                    }}
                    google={this.props.google}
                    initialCenter={{
                        lat: this.props.mapMarker.centerX,
                        lng: this.props.mapMarker.centerY,
                    }}
                    zoom={this.props.zoom}
                >
                    {markerJSX()}
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDhmUw1pMO8HBhcbwu0-axtqIwxYF3C4KY'
})(ContactMap);