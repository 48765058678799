import React from 'react';
import Job from './Job';
import {Row} from "react-bootstrap";

const Jobs = (props) => {

   const renderCareerPosts = () => {
      if(props.jobs && props.jobs.length > 0) {
         return props.jobs.map(el => <Job
             key={el.id}
             id={el.id}
             title={el.title}/>)
      }

      return <h3 className={'text-center'}>{props.getStr('no_results_career') || 'არ არსებობს ვაკანსიები'}</h3>
   }

   return (
      <Row className={'justify-content-center'}>
         {renderCareerPosts()}
      </Row>
   );
};

export default Jobs;