import React, {Component, createRef} from 'react';
import {imagePrepath} from "../../helpers";
import TitleBanner from "../common/TitleBanner/TitleBanner";
import bannerImg from '../../assets/images/banner2.jpg'
import {Col, Container, Row} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import CheckoutForm from "./CheckoutForm";
import s from './Checkout.module.css'
import CheckoutFormInputs from "./CheckoutFormInputs";
import PhoneValidator from "./PhoneValidator";
import OrderDetails from "./OrderDetails";
import withPopup from "../../hoc/withPopup/withPopup";
import {connect} from "react-redux";
import apiHandler from "../../api/api";
import {checkoutFormSubmit, clearCart, getCart, sendVerifCode} from "../../store/reducers/cartReducer";
import gsap from 'gsap';
import Loader from "../common/Loader/Loader";
import PropTypes from "prop-types";
import withStr from "../../hoc/withStr";

class Checkout extends Component {
   constructor(props) {
      super(props);

      const currentDate = new Date();
      let minutes = String(currentDate.getMinutes());
      minutes = minutes.length === 1 ? '0' + minutes : minutes;

      const currentTime = currentDate.getHours() + ':' + minutes;

      this.state = {
         deliveryOptions: [],
         paymentOptions: [],
         chosenDeliveryMethod: 1,
         showPhoneValidatorPopup: false,
         formData: null,
         inputDate: new Date(),

         deliveryTime: currentTime,
         showTimePicker: false,

         mapMarker: {
            x: null,
            y: null,
            centerX: 41.7197567,
            centerY: 44.767644499999996,
         },

         addressInput: {
            name: 'address',
            label: 'მისამართი',
            value: '',
            error: '',
            touched: false,
         },

         serverError: '',
         init: false,
      }
   }

   checkoutSuccessRef = createRef();
   checkoutFormRef = createRef();

   getAddressError = (value) => {
      let error = '';
      if(!value || value.length === 0){
         error = 'Required';
      }
      return error;
   }

   addressChangeHandler = (value) => {
      this.setState({
         addressInput: {
            ...this.state.addressInput,
            value: value,
            error: this.getAddressError(value),
         }
      })
   }

   addressBlurHandler = (value) => {
      this.setState({
         addressInput: {
            ...this.state.addressInput,
            touched: true,
            error: this.getAddressError(value),
         }
      })
   }

   setMapMarker = (lat, ln, setCenter=false) => {
      let centerCoords = {};
      if(setCenter){
         centerCoords = {
            centerX: lat,
            centerY: ln,
         }
      }

      this.setState({
         mapMarker: {
            x: lat,
            y: ln,
            ...centerCoords,
         }
      })
   }

   showTimePicker = (show) => {
      this.setState({
         showTimePicker: show,
      });
   }

   setTime = (time) => {
      this.setState({
         deliveryTime: time,
      })
   }

   showCalendar = () => {
      const deliveryMethod = parseInt(this.state.chosenDeliveryMethod);
      const res = this.state.deliveryOptions.findIndex((el) => {
         return el.type === 1 && el.id === deliveryMethod
      });

      return res !== -1;
   }

   componentDidMount() {
      // scrolling to top when somebody visits this page
      // this.scrollToTop(this.checkoutFormRef.current);
      window.scroll(0, 0);
      this.setDeliveryAndPaymentMethods();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (!this.state.init && this.state.deliveryOptions.length > 0 && this.state.paymentOptions.length > 0) {
         gsap.set(this.checkoutSuccessRef.current, {display: 'none', opacity: 0});
         this.setState({
            init: true,
         })
      }
   }

   setDeliveryAndPaymentMethods = () => {
      Promise.all([apiHandler('getPaymentTypes'), apiHandler('getDeliveryTypes')])
         .then(res => {
            const paymentOptionsArr = res[0].map(el => ({
               id: el.id,
               name: el.name,
               type: el.type
            }));

            const deliveryOptionsArr = res[1].map(el => ({
               id: el.id,
               name: el.name,
               type: el.type,
               price: el.price,
               free_after: el.free_after,
            }));


            this.setState({
               paymentOptions: paymentOptionsArr,
               deliveryOptions: deliveryOptionsArr,
            });

         })
   }

   onClosePopup = () => {
      this.setState({
         showPhoneValidatorPopup: false,
         formData: null,
      })
   }

   onShowPopup = (dataObj) => {

      dataObj.deliveryScheduled = this.state.inputDate;
      console.log(dataObj);

      this.props.sendVerifCode(dataObj.phone)

      dataObj.street = this.state.addressInput.value;

      this.setState({
         showPhoneValidatorPopup: true,
         formData: dataObj,
      });

   }

   onDeliveryChangedHandler = (val, setFieldValue) => {
      setFieldValue('deliveryMethod', val);
      this.setState({
         chosenDeliveryMethod: parseInt(val)
      });
   }

   scrollToTop = (elem) => {
      const y = elem.getBoundingClientRect().top + window.scrollY - 100;
      window.scroll({
         top: y,
         behavior: 'smooth'
      });
   }

   redirectToInvoice = (res) => {
      if (res.invoice) {
         if (process.env.REACT_APP_APIManager_HOSTNAME) {
            window.open(process.env.REACT_APP_APIManager_HOSTNAME + res.invoice, '_self')
         } else {
            const urlArr = window.location.href.split('/');
            window.open(urlArr[0] + '//' + urlArr[2] + res.invoice, '_self')
         }
      }
   }

   redirectPost = (url, data) => {
      const form = document.createElement('form');
      document.body.appendChild(form);
      form.method = 'post';
      form.action = url;
      for (const name in data) {
         const input = document.createElement('input');
         input.type = 'hidden';
         input.name = name;
         input.value = data[name];
         form.appendChild(input);
      }
      form.submit();
   }

   onSubmitHandler = (verifCode) => {
      this.setState({
         serverError: ''
      });

      this.props.onFormSubmit(this.state.formData, verifCode, this.showCalendar(), this.state.mapMarker)
         .then(res => {
            if (res.status === 0 && res.message === 'OK') {

               this.setState({
                  showPhoneValidatorPopup: false,
                  formData: null,
               });

               this.props.clearCart();

               gsap.to(this.checkoutSuccessRef.current, {display: 'flex', opacity: 1});
               setTimeout(() => {
                  gsap.to(this.checkoutSuccessRef.current, {
                     display: 'none', opacity: 0,
                     onComplete: () => {
                        this.redirectToInvoice(res);
                     }
                  });
               }, 2000);

            } else if(res.status === 1 && res.hasOwnProperty('redirect') && res.redirect.hasOwnProperty('POST') && res.redirect.hasOwnProperty('url')){
               this.redirectPost(res.redirect.url, res.redirect.POST);
            } else {
               this.setState({
                  showPhoneValidatorPopup: false,
                  formData: null,
                  serverError: res.message,
               });
               this.scrollToTop(this.checkoutFormRef.current);
            }


         })
   }

   onInputDateChanged = (date) => {
      this.setState({
         inputDate: date
      })
   }

   render() {

      if (this.state.deliveryOptions.length === 0 || this.state.paymentOptions.length === 0) {
         return <Loader/>
      }

      let deliveryPrice;
      if (this.state.deliveryOptions.length > 0) {
         deliveryPrice = this.state.deliveryOptions.reduce((accum, cur) => {
            if (parseInt(this.state.chosenDeliveryMethod) === parseInt(cur.id)) {
               return accum + parseFloat(cur.price);
            }
            return accum;
         }, 0)
      }


      return (
         <>
            <div ref={this.checkoutSuccessRef} className={s.checkoutSuccessWrp}>
               <div className={s.bgDarkTransparent}></div>
               <div class={s.checkoutSuccessText}>{this.props.getStr('checkout_success')}<i className="fas fa-check-circle ml-2"></i></div>
            </div>

            <PhoneValidator
               onSubmitHandler={this.onSubmitHandler}
               onClosePopup={this.onClosePopup}
               showPhoneValidatorPopup={this.state.showPhoneValidatorPopup}
            />

            <div className={s.wrp}>
               <TitleBanner imageURL={bannerImg} text={this.props.getStr('checkout')}/>
               <Container>
                  <Row>
                     <Col className={'order-lg-2'} xs={12} lg={5} xl={4}>
                        <OrderDetails
                           onCartItemRemoved={this.props.removeCartItem}
                           onEditCartItem={this.props.onEditCartItem}
                           deliveryPrice={deliveryPrice}
                           cartData={this.props.cartData}
                           getStr={this.props.getStr}
                        />
                     </Col>
                     <Col className={'order-lg-1'} xs={12} lg={7} xl={8}>
                        <div ref={this.checkoutFormRef}>
                           <CheckoutForm
                              deliveryTime={this.state.deliveryTime}
                              showTimePicker={this.state.showTimePicker}
                              showTimePickerHandler={this.showTimePicker}
                              setTime={this.setTime}

                              mapMarker={this.state.mapMarker}
                              setMapMarker={this.setMapMarker}
                              addressChangeHandler={this.addressChangeHandler}
                              addressBlurHandler={this.addressBlurHandler}
                              addressInput={this.state.addressInput}

                              showCalendar={this.showCalendar}
                              serverError={this.state.serverError}
                              cartData={this.props.cartData}
                              inputDate={this.state.inputDate}
                              onInputDateChanged={this.onInputDateChanged}
                              onDeliveryChangedHandler={this.onDeliveryChangedHandler}
                              onShowPopup={this.onShowPopup}
                              deliveryOptions={this.state.deliveryOptions}
                              paymentOptions={this.state.paymentOptions}

                              getStr={this.props.getStr}
                           />
                        </div>
                     </Col>

                  </Row>
               </Container>
            </div>
         </>
      );
   }
}

const mapStateToProps = state => ({
   cartData: state.cart.cart
});

const mapDispatchToProps = dispatch => ({
   onFormSubmit: (dataObj, verifCode, isScheduled, marker) => dispatch(checkoutFormSubmit(dataObj, verifCode, isScheduled, marker)),
   sendVerifCode: (phone) => dispatch(sendVerifCode(phone)),
   clearCart: () => dispatch(clearCart())
});

export default connect(mapStateToProps, mapDispatchToProps)(withStr(withPopup(Checkout)));