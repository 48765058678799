import React from "react";
import {Col, Container, Dropdown, Row} from 'react-bootstrap';
import LogoUrl from '../../../assets/images/logo.png';
import s from './DesktopNavbar.module.css';
import BaseBtn from "../../common/NormalBtns/BaseBtn";
import {Link} from "react-router-dom";
import {linkWithLang} from "../../../helpers/helpers";

const DesktopNavbar = (props) => {

    const chosenLanguage = props.languages.find(el => el.id === props.langId).name;
    const otherLanguages = props.languages.map(el => {
        if(el.id !== props.langId && el.enabled === 1) {
            return <div className={'dropdown-item'} onClick={() => props.chooseLangHandler(el.id)}>{el.name}</div>
        }
    })

    return (
        <div className={s.mainWrp}>
            <div className={s.desktopNavbar}>
                <Container>
                    <Row className={'justify-content-center'}>
                        <Col lg={12}>
                            <Row>

                                <Col md={3}>
                                    <div className={s.LogoWrp}>
                                        <Link to={linkWithLang('/')}>
                                            <img src={LogoUrl} className={s.Logo} alt="logo"/>
                                        </Link>
                                    </div>
                                </Col>

                                <Col md={7}>
                                    <div className={'d-flex align-items-center'}>
                                        <nav className={s.navItemsOuterWrp}>
                                            <ul className={s.navItemsInnerWrp}>


                                                <li className={`${s.navItem} font-weight-bold`}>
                                                    <Link to={linkWithLang('/')}>{props.getStr('nav_home')}</Link>
                                                </li>

                                                <Link to={linkWithLang('/categories')} className={`${s.navItem}`}>
                                                    <BaseBtn
                                                        className={'font-weight-bold'}
                                                        btnType={'Yellow'}
                                                        text={props.getStr('nav_shop')}
                                                    />
                                                </Link>

                                                <li className={`${s.navItem} font-weight-bold`}>
                                                    <Link to={linkWithLang('/blog')}>{props.getStr('nav_blog')}</Link>
                                                </li>

                                                <li className={`${s.navItem} font-weight-bold`}>
                                                    <Link to={linkWithLang('/career')}>{props.getStr('nav_career')}</Link>
                                                </li>

                                                {props.pageNames.map(el => {
                                                    return (
                                                        <li className={`${s.navItem} font-weight-bold`}>
                                                            <Link to={linkWithLang(`/pages/${el.path}`)}>{el.name}</Link>
                                                        </li>
                                                    )
                                                })}

                                                <li className={`${s.navItem} font-weight-bold`}>
                                                    <Link to={linkWithLang('/contact')}>{props.getStr('nav_contact')}</Link>
                                                </li>




                                            </ul>
                                        </nav>
                                    </div>
                                </Col>

                                <Col md={2}>
                                    <div className={`d-flex justify-content-end align-items-center h-100 ${s.cartWrp}`}>

                                        <Dropdown className={'mr-3'}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {chosenLanguage}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {otherLanguages}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {/*<Link className={`${s.langToggle}`} to={props.newLangUrl}/>*/}

                                        <div className={`d-flex align-items-center ${s.cartIconWrp}`}
                                             onClick={props.onCartBtnClicked}>
                                            <div className={'position-relative'}>
                                                <div
                                                    className={`d-flex align-items-center justify-content-center ${s.cartAmount}`}>
                                                    {props.cartItemsAmount}
                                                </div>
                                                <i className={`fas fa-shopping-cart ${s.cartIcon}`}></i>
                                            </div>
                                        </div>

                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div onClick={props.onCartBtnClicked} className={`${s.cartFixedWrp} d-none`}>
                <div className={`${s.cartFixedInnerWrp}`}>
                    <div
                        className={`d-flex align-items-center justify-content-center ${s.cartAmount}`}>{props.cartItemsAmount}</div>
                    <i className={`fas fa-shopping-cart ${s.cartIcon}`}></i>
                </div>
            </div>

        </div>
    )
}

export default DesktopNavbar;