import React from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Col} from "react-bootstrap";
import s from './CheckoutMap.module.css';
import CheckoutFormInputs from "./CheckoutFormInputs";
import Geocode from "react-geocode";


class CheckoutMap extends React.PureComponent {

   onMapClicked = (mapProps, map, clickEvent) => {
      // props.mapMarker
      const lat = clickEvent.latLng.lat();
      const lng = clickEvent.latLng.lng();
      Geocode.fromLatLng(lat, lng).then(
         response => {
            debugger;
            const address = response.results[0].formatted_address;
            this.props.setAddressValue(address);
         },
         error => {
            console.error(error);
         }
      );

      this.props.setMapMarker(clickEvent.latLng.lat(), clickEvent.latLng.lng());
      // debugger;
   }

   componentDidMount() {
      Geocode.setApiKey('AIzaSyDhmUw1pMO8HBhcbwu0-axtqIwxYF3C4KY');
      Geocode.setLanguage("en");

      // get current location
      if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition((pos) => {
            this.props.setMapMarker(pos.coords.latitude, pos.coords.longitude, true);
            Geocode.fromLatLng(pos.coords.latitude, pos.coords.longitude).then(
               response => {
                  const address = response.results[0].formatted_address;
                  this.props.setAddressValue(address);
               },
               error => {
                  console.error(error);
               }
            );
         });
      }
      // /.
   }

   render() {

      const markerJSX = () => {
         if (this.props.mapMarker.x && this.props.mapMarker.y) {
            return <Marker position={{lat: this.props.mapMarker.x, lng: this.props.mapMarker.y}}/>
         }
         return null
      }

      return (
         <div className={`position-relative ${s.mapWrp}`}>
            <Map
               onClick={this.onMapClicked}
               style={{
                  width: '100%',
                  height: '100%',
                  position: 'relative'
               }}
               containerStyle={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0',
                  left: '0'
               }}
               google={this.props.google}
               initialCenter={{
                  lat: this.props.mapMarker.centerX,
                  lng: this.props.mapMarker.centerY,
               }}
               center={{
                  lat: this.props.mapMarker.centerX,
                  lng: this.props.mapMarker.centerY,
               }}
               zoom={14}
            >
               {markerJSX()}
            </Map>
         </div>
      );
   }
}

export default GoogleApiWrapper({
   apiKey: 'AIzaSyDhmUw1pMO8HBhcbwu0-axtqIwxYF3C4KY'
})(CheckoutMap);