import React from 'react';
import s from './Ingredient.module.css';
import Checkbox from "../../../components/common/Checkbox/Checkbox";
import Radio from "../../../components/common/Radio/Radio";

const Ingredient = props => {

   const priceModifier = props.priceModif[0] !== '-'
      ? `+${props.priceModif}`
      : `-${props.priceModif.slice(1, props.priceModif.length)}`

   return (
      <div onClick={props.onIngredientClicked} className={s.wrp}>
         <div className={'d-flex'}>
         { props.type === 1
            ? <Checkbox isChecked={props.isChecked} name={props.name} />
            : <Radio isChecked={props.isChecked} name={props.name} />
         }
            <div className={`ml-2 ${s.priceModif}`}>{priceModifier}</div>
         </div>
      </div>
   );
}

export default Ingredient;