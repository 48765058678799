import React, {Component, createRef} from "react";
import s from './Shop.module.css';
import TitleBanner from "../common/TitleBanner/TitleBanner";

import bannerImg from '../../assets/images/banner1.jpg';
import {Col, Container, Dropdown, Row} from "react-bootstrap";
import Products from "../Products/Products";
import Filters from "./Filters/Filters";
import BaseBtn from "../common/NormalBtns/BaseBtn";
import {
    getCategories,
    getProductByCategory,
    getProducts,
    getProductsByCategory, PRODUCTS_LIMIT
} from "../../store/reducers/shopPageReducer";
import withPopup from "../../hoc/withPopup/withPopup";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Loader from "../common/Loader/Loader";
import withStr from "../../hoc/withStr";
import {compose} from "redux";

class Shop extends Component {
    state = {
        filters: {
            catId: null,
            minPrice: null,
            maxPrice: null,
            search: '',
            sort: 'ASC',
        },
        searchField: '',
        initPriceRange: false,
        page: 0,

        productsLoaded: false,
    }

    productsWrpRef = createRef()

    changePriceRangeInit = (state) => {
        this.setState({
            initPriceRange: state,
        })
    }

    getQueryParams = () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        const newObj = {
            catId: searchParams.get('catId') ? parseInt(searchParams.get('catId')) : null,
            minPrice: searchParams.get('minPrice') ? parseInt(searchParams.get('minPrice')) : this.props.minPriceEdge,
            maxPrice: searchParams.get('maxPrice') ? parseInt(searchParams.get('maxPrice')) : this.props.maxPriceEdge,
            search: searchParams.get('search') ?? '',
            sort: searchParams.get('sort') ? String(searchParams.get('sort')).toUpperCase() : 'ASC',
            page: searchParams.get('page') ?? 0,
        }

        return newObj;
    }

    setQueryParams = ({catId, minPrice, maxPrice, search, sort, page}) => {
        //set category in state
        this.setState({
            filters: {
                catId,
                minPrice,
                maxPrice,
                search,
                sort,
            },
            page,
        })
    }

    onPriceRangeChanged = (minPrice, maxPrice) => {
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.set('minPrice', minPrice);
        searchParams.set('maxPrice', maxPrice);

        this.props.history.push(this.props.location.pathname + '?' + searchParams.toString());
    }

    filtersShallowCompare = (queryParams, prevState) => {
        const diffObj = {
            catId: false,
            minPrice: false,
            maxPrice: false,
            search: false,
            sort: false,
            general: false,
        }

        for (const propName in this.state.filters) {
            if (this.state.filters[propName] !== queryParams[propName] || this.state.page !== queryParams.page) {
                // diffObj[propName] = true;
                diffObj.general = true;
            }
        }
        return diffObj;
    }

    componentDidMount() {
        const queryParams = this.getQueryParams();
        // fetch and set categories
        this.props.getCategories();

        this.setState({
            searchField: queryParams.search,
        }, () => {
            this.setQueryParams(queryParams);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
       console.log(this.props);

        const queryParams = this.getQueryParams();
        const diffObj = this.filtersShallowCompare(queryParams, prevState);
        if (diffObj.general) {
            // debugger;
            this.setQueryParams(queryParams);

        } else if (this.state.filters !== prevState.filters) {

            if (this.state.filters.catId) {

                // fetch and set products
                this.setState({
                    productsLoaded: false,
                });

                this.props.getProductsByCategory(this.state.filters, this.state.page)
                    .then(() => {
                        this.setState({
                            productsLoaded: true,
                        });
                        //scroll top top
                        window.scroll(0, 0);
                    })
            } else {
                // fetch and set products
                this.setState({
                    productsLoaded: false,
                })

                this.props.getProducts(this.state.filters, this.state.page)
                    .then(() => {
                        this.setState({
                            productsLoaded: true,
                        })
                        //scroll top top
                        window.scroll(0, 0);
                    })
            }
        }
    }

    onSearchSubmitHandler = (e) => {
        e.preventDefault();
        this.props.history.push(`/shop?search=${this.state.searchField}`);
    }

    priceRangeHanlder = () => {
        const newQueryParams = new URLSearchParams(this.props.location.search);
        if (this.state.filters.minPrice < this.props.minPriceEdge) {
            newQueryParams.set('minPrice', this.props.minPriceEdge);
        }

        if (this.state.filters.maxPrice > this.props.maxPriceEdge) {
            newQueryParams.set('maxPrice', this.props.maxPriceEdge);
        }

        this.props.history.push('?' + newQueryParams.toString());
    }


    render() {

        const productSizeMixins = {
            sm: 6,
        }

        const filterJSX = () => {
            // if(this.props.categories && (this.state.filters.minPrice || this.state.filters.minPrice === 0) && this.state.filters.maxPrice){
            if (this.props.categories) {
                return <Filters
                    catId={this.state.filters.catId}
                    categories={this.props.categories}
                    minPriceEdge={this.props.minPriceEdge}
                    maxPriceEdge={this.props.maxPriceEdge}
                    minPrice={this.state.filters.minPrice}
                    maxPrice={this.state.filters.maxPrice}
                    priceRangeHandler={this.priceRangeHandler}
                    onPriceRangeChanged={this.onPriceRangeChanged}

                    initPriceRange={this.state.initPriceRange}
                    changePriceRangeInit={this.changePriceRangeInit}
                    priceRangeHanlder={this.priceRangeHanlder}
                />
            }

            return null;
        }

        const sortLinks = {
            asc: new URLSearchParams(this.props.location.search),
            desc: new URLSearchParams(this.props.location.search),
        }
        sortLinks.asc.set('sort', 'asc');
        sortLinks.desc.set('sort', 'desc');


        const countJSX = () => {
            const countJSXArr = [];
            for (let x = 0; x < this.props.count / PRODUCTS_LIMIT; x++) {
                const queryParams = new URLSearchParams(this.props.location.search);
                queryParams.set('page', x);
                countJSXArr.push(
                    <Link to={'?' + queryParams.toString()}
                          className={`${s.pag} ${parseInt(this.state.page) === x ? s.active : ''}`}>
                        {x + 1}
                    </Link>
                )
            }

            return (
                <div className={'d-flex'}>
                    {countJSXArr}
                </div>
            );
        }

        const sortJSX = () => (
            <Col xs={5} lg={5} className={s.sortByDesktopWrp}>
                <div className={'d-flex justify-content-end'}>
                    <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic" className={s.sortByDesktop}>
                            {this.props.getStr('sort')}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className={'py-0'}>
                            <Link to={`?${sortLinks.asc.toString()}`} className={s.dropdownItem}>{this.props.getStr('asc')}</Link>
                            <Link to={`?${sortLinks.desc.toString()}`} className={s.dropdownItem}>{this.props.getStr('desc')}</Link>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Col>
        )


        const searchJSX = () => (
            <Col xs={7} lg={7}>
                <form onSubmit={this.onSearchSubmitHandler}>
                    <div className={'d-flex'}>
                        <input
                            type="text"
                            placeholder={this.props.getStr('search_placeholder')}
                            value={this.state.searchField}
                            onChange={(e) => {
                                const newObj = {
                                    searchField: e.target.value
                                }

                                this.setState(newObj);

                                console.log(this.state);

                            }}
                            className={`${s.searchForm} form-control py-4`}
                        />
                        <button className={s.searchBtn}>{this.props.getStr('search')}</button>
                    </div>
                </form>
            </Col>
        )


        return (
            <div>
                {!this.state.productsLoaded
                    ? <Loader/>
                    : null
                }
                {/*<TitleBanner imageURL={bannerImg} text={'Shop'} />*/}
                <div className={s.wrp}>
                    <Container>
                        <Row>

                            <Col lg={4}>
                                {filterJSX()}
                            </Col>

                            <Col lg={8} className={'mb-4'}>
                                <Row>

                                    {searchJSX()}

                                    {sortJSX()}

                                    <Col ref={this.productsWrpRef} xs={12} className={'mt-4'}>
                                        {
                                            this.state.productsLoaded && (!this.props.products || this.props.products.length === 0)
                                                ? <div>No products found for this category</div>
                                                : <Products
                                                    onRenderPopup={this.props.onRenderPopupHandler}
                                                    productsArr={this.props.products}
                                                    sizeMixins={productSizeMixins}/>
                                        }


                                        {countJSX()}
                                        {/*<div className={'text-center mt-5'}>*/}
                                        {/*   <BaseBtn className={'px-4'} btnType={'Gray'} text={'More'} size={'md'} />*/}
                                        {/*</div>*/}
                                    </Col>

                                </Row>

                            </Col>


                            {/*<Col xs={12} className={s.sortByMobileWrp}> {}*/}
                            {/*   <Dropdown>*/}
                            {/*      <Dropdown.Toggle variant="light" id="dropdown-basic2" className={s.sortByDesktop}>*/}
                            {/*         Sort By*/}
                            {/*      </Dropdown.Toggle>*/}

                            {/*      <Dropdown.Menu className={'py-0'}>*/}
                            {/*         <Link to={`?${sortLinks.asc.toString()}`} className={s.dropdownItem}>Price Low to High</Link>*/}
                            {/*         <Link to={`?${sortLinks.desc.toString()}`} className={s.dropdownItem}>Price High to Low</Link>*/}
                            {/*      </Dropdown.Menu>*/}
                            {/*   </Dropdown>*/}
                            {/*</Col>*/}


                        </Row>

                    </Container>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    categories: state.shop.categories,
    products: state.shop.products,
    minPriceEdge: state.shop.minPriceEdge,
    maxPriceEdge: state.shop.maxPriceEdge,
    count: state.shop.count,
    page: state.shop.page,
});

const mapDispatchToProps = dispatch => ({
    getProducts: (data, page) => dispatch(getProducts(data, page)),
    getProductsByCategory: (data, page) => dispatch(getProductsByCategory(data, page)),
    getCategories: () => dispatch(getCategories())
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPopup,
    withStr,
)(Shop);